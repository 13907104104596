import { formatDecimalString, toFloat } from "./utils";

export type W3Number = {
  hStr: string;
  dsp: number;
  big: bigint;
};

export const toW3Number = (
  num: bigint,
  dec: number,
  displayDec: number
): W3Number => {
  return {
    hStr: formatDecimalString(num, dec, displayDec),
    dsp: toFloat(num, dec),
    big: num,
  };
};

import { W3Number } from "@/lib/w3Num";
import { Pool } from "@/pages/Pools";
import { useCallback, useEffect, useMemo, useState } from "react";
import { APPROVE_DELAY_MS, useApprove } from "../useApprove";
import { toast } from "../useToast";
import { sleep } from "@/lib/utils";
import { formatUnits } from "viem";

export const useLiquidityDepositOneSided = (
  pool: Pool,
  amountA: W3Number | null,
  setAmountB: (val: W3Number | null) => void
) => {
  const [lptAmount, setLptAmount] = useState<bigint | null>(null);
  const [lptValueUsd, setLptValueUsd] = useState<number | null>(null);

  useEffect(() => {
    setAmountB(null);
    setLptAmount(null);
    setLptValueUsd(null);
  }, [setAmountB]);

  const {
    approveRequired: approveQuoteRequired,
    executeApprove: executeApproveQuote,
  } = useApprove(pool.quoteToken.id, pool.id, amountA);
  const {
    approveRequired: approveBaseRequired,
    executeApprove: executeApproveBase,
  } = useApprove(pool.baseToken.id, pool.id, amountA);

  // Calc other side when tokenA is edited
  useEffect(() => {
    const calcDepositTokenB = async () => {};

    calcDepositTokenB();
  }, []);

  // Calc pool percent
  const lptPercentToReceive = useMemo(() => {
    if (lptAmount) {
      const toReceive = parseFloat(formatUnits(lptAmount, 18));
      const totalSupply = parseFloat(pool.lptTotal);
      return (100 * toReceive) / (toReceive + totalSupply);
    }
    return null;
  }, [lptAmount, pool.lptTotal]);

  const deposit = useCallback(() => {
    const executeDeposit = async () => {
      console.log("approve quote token required", approveQuoteRequired);
      if (approveQuoteRequired) {
        toast({ title: `Approving ${pool.quoteToken.symbol}...` });
        const res = await executeApproveQuote();
        if (res === null) return; // early exit: approval failed
        toast({
          title: `Approval for ${pool.quoteToken.symbol} successful`,
        });

        await sleep(APPROVE_DELAY_MS); // X second delay
      }

      console.log("approve base required", approveBaseRequired);
      if (approveBaseRequired) {
        toast({ title: `Approving ${pool.baseToken.symbol}...` });
        const res = await executeApproveBase();
        if (res === null) return; // early exit: approval failed
        toast({
          title: `Approval for ${pool.baseToken.symbol} successful`,
        });

        await sleep(APPROVE_DELAY_MS); // X second delay
      }
    };

    executeDeposit();
  }, [
    pool.baseToken.symbol,
    pool.quoteToken.symbol,
    approveBaseRequired,
    approveQuoteRequired,
    executeApproveBase,
    executeApproveQuote,
  ]);

  return {
    deposit,
    lptAmount,
    lptValueUsd,
    lptPercentToReceive,
  };
};

import { Link } from "react-router-dom";
import { ResetIcon } from "@radix-ui/react-icons";

import { Footer } from "@/components/footer";
import { Header } from "@/components/header";

export const ErrorPage: React.FC = () => {
  return (
    <div className="flex flex-col min-h-screen">
      <div className="flex flex-grow flex-col px-[5%] sm:px-[10%] lg:px-[20%] relative">
        <div className="absolute z-[-1] w-full top-0 left-0 py-2 dark:py-1 bg-gradient-to-b from-muted dark:from-sky-200/50 to-transparent" />
        <Header />
      </div>

      <div className="flex flex-grow m-auto items-center">
        <div className="text-4xl text-secondary-foreground font-extrabold">
          404
        </div>
        <div className="ml-2 text-2xl text-secondary-foreground/80 font-medium">
          Not Found
        </div>
      </div>

      <div className="flex flex-grow m-auto">
        <div>
          <Link
            to="/"
            className="flex items-center text-secondary-foreground/50 hover:text-secondary-foreground/30 gap-x-2"
          >
            Return home <ResetIcon className="scale-y-[-1]" />
          </Link>
        </div>
      </div>
      <div className="flex flex-grow"></div>

      <div className="p-10 px-4 md:px-8 bottom-0 relative">
        <Footer />
        <div className="absolute z-[-1] w-full bottom-0 left-0 py-40 bg-gradient-to-t from-muted to-transparent dark:from-black" />
      </div>
    </div>
  );
};

import { useState, useCallback } from "react";
import { SpringValue, useSpring } from "react-spring";
import usePrefersReducedMotion from "./usePrefersReducedMotion";

interface Args {
  x?: number;
  y?: number;
  z?: number;
  rotation?: number;
  rx?: number;
  ry?: number;
  rz?: number;
  scale?: number;
  sx?: number;
  sy?: number;
  sz?: number;
  skewX?: number;
  skewY?: number;
  springConfig?: {
    tension?: number;
    friction?: number;
  };
  delay?: number;
}

interface SpringStyle {
  transform?: SpringValue<string>;
}

type Boop = (args: Args) => [SpringStyle, boolean, () => void, () => void];

export const useBoop: Boop = ({
  x = 0,
  y = 0,
  z = 0,
  rotation = 0,
  rx = 0,
  ry = 0,
  rz = rotation,
  scale = 1,
  sx = scale || 1,
  sy = scale || 1,
  sz = scale || 1,
  skewX = 0,
  skewY = 0,
  springConfig = {
    tension: 120,
    friction: 20,
  },
  delay = 0,
} = {}) => {
  const prefersReducedMotion = usePrefersReducedMotion();
  const [boopedAt, setBoopedAt] = useState(0);
  const style: SpringStyle = useSpring(
    prefersReducedMotion
      ? {}
      : {
          transform:
            boopedAt > 0
              ? `translate3D(${x}px, ${y}px, ${z}px)
         rotateX(${rx}deg) rotateY(${ry}deg) rotateZ(${rz}deg)
         scale3D(${sx},${sy},${sz})
         skew(${skewX}deg, ${skewY}deg)`
              : `translate3D(0px, 0px, 0px)
         rotateX(0deg) rotateY(0deg) rotateZ(0deg)
         scale3D(1, 1, 1)
         skew(0deg, 0deg)`,
          config: springConfig,
          immediate: prefersReducedMotion,
        }
  );

  const triggerOpen = useCallback(() => {
    if (!prefersReducedMotion) {
      setBoopedAt(Date.now());
    }
  }, [prefersReducedMotion]);

  const triggerClose = useCallback(() => {
    if (!prefersReducedMotion) {
      if (Date.now() - boopedAt > delay) {
        setBoopedAt(0);
      } else if (boopedAt > 0) {
        const timeoutId = window.setTimeout(() => setBoopedAt(0), delay);
        return () => window.clearTimeout(timeoutId);
      }
    }
  }, [delay, boopedAt, prefersReducedMotion]);

  return [style, boopedAt > 0, triggerOpen, triggerClose];
};

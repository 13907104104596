import { useConfig, usePublicClient } from "wagmi";
import { switchChain } from "@wagmi/core";

import { Button } from "@/components/ui/override/button";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { config } from "@/config/config";
import { toast } from "@/hooks/useToast";
import { GlobeIcon } from "@radix-ui/react-icons";

export const NetworkSelect: React.FC = () => {
  const client = usePublicClient();
  const { chains } = useConfig();

  const handleNetworkSwitch = async (value: string = "0") => {
    const idx = Number(value);
    try {
      const selectedChain = config.chains[idx];

      await switchChain(config, {
        chainId: selectedChain.id,
      });

      toast({
        title: `Switched to the ${selectedChain.name} network`,
      });
    } catch (error: any) {
      toast({
        title: "Network switch failed",
        description: error.message || "An unknown error occurred",
      });
    }
  };

  return (
    <>
      {client ? (
        <Select onValueChange={handleNetworkSwitch}>
          <SelectTrigger
            aria-label="Select network"
            className="bg-secondary text-white px-4 py-2 rounded-sm [&>svg]:text-primary-foreground/80"
          >
            <GlobeIcon height={16} width={16} className="mr-2" />
            <SelectValue placeholder={client.chain.name} />
          </SelectTrigger>

          <SelectContent>
            <SelectGroup>
              {chains.map((chain, i) => (
                <SelectItem key={chain.id} value={String(i)}>
                  {chain.name}
                </SelectItem>
              ))}
            </SelectGroup>
          </SelectContent>
        </Select>
      ) : (
        <Button
          variant="destructive"
          aria-label="Select network"
          onClick={() => handleNetworkSwitch()}
        >
          Incorrect Network
        </Button>
      )}
    </>
  );
};

import { clsx, type ClassValue } from "clsx";
import { twMerge } from "tailwind-merge";
import { formatUnits } from "viem";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

/**
 * bigint
 */
// format bigint to decimal string
export const formatValue = (value: bigint | null, dec: number = 18): string => {
  if (value === null) value = 0n;
  return formatUnits(value, dec);
};

// format bigint to decimal string and truncate
export const formatDecimalString = (
  value: bigint | number | null | undefined,
  dec: number = 18,
  displayDec: number = 2
): string => {
  if (typeof value === "number") {
    return value.toFixed(displayDec);
  }
  if (value === 0n || !!value) {
    return Number(formatValue(value, dec)).toFixed(displayDec);
  } else {
    return "--";
  }
};

/**
 * String
 */
export const capitalize = (word: string): string => {
  return word.charAt(0).toUpperCase() + word.slice(1);
};

export const truncateHash = (hash: string, chars = 4) =>
  `${hash.slice(0, chars)}...${hash.slice(-chars)}`;

/**
 * Number
 */
// BigInt to float
export const toFloat = (amount: bigint, decimals = 6): number => {
  return Number(formatUnits(amount, decimals));
};

export const toMaxSigFig = (
  amount: number | undefined,
  decimals = 2
): string => {
  if (amount) {
    return String(Number(amount.toFixed(decimals)));
  }
  return "--";
};

/**
 * Logging
 */
export const debug = (msg?: any, ...optionalParams: any[]) => {
  if (process.env.NODE_ENV === "development")
    console.log(msg, ...optionalParams);
};

/**
 * Timing
 */
export const sleep = (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms));

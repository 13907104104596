import { useCallback } from "react";
import { useAtomValue } from "jotai";
import { encodeFunctionData } from "viem";
import { simulateContract, waitForTransactionReceipt } from "@wagmi/core";

import { IDfxRouterAbi } from "@/abi/IDfxRouter";
import { BASE_ROUTER_ADDR } from "@/config/addresses";
import { config } from "@/config/config";
import { txSettingsAtom } from "@/contexts/atoms";
import { SwapField } from "@/lib/constants";
import { getDeadlineEpochTime } from "@/lib/time-utils";
import { W3Number } from "@/lib/w3Num";
import { sleep } from "@/lib/utils";
import useAdaptiveWallet from "./useAdaptiveWallet";
import { APPROVE_DELAY_MS, useApprove } from "./useApprove";
import { useCalcSwap } from "./useCalcSwap";
import { Token } from "./usePools";
import { useSwapRoute } from "./useSwapRoute";
import { toast } from "./useToast";

export const useSwap = (
  inToken: Token | null,
  outToken: Token | null,
  inAmount: W3Number,
  outAmount: W3Number,
  exactToken: SwapField
) => {
  const txSettings = useAtomValue(txSettingsAtom);

  const { sendTransaction: adaptiveSendTransaction } = useAdaptiveWallet();
  const { tokenRoute } = useSwapRoute(inToken, outToken);
  const {
    estimatedInAmount,
    estimatedOutAmount,
    minInAmount,
    minOutAmount,
    spotPrice,
    swapError,
  } = useCalcSwap({
    inToken,
    outToken,
    inAmount,
    outAmount,
    exactToken,
  });
  const { approveRequired, executeApprove } = useApprove(
    inToken ? inToken.id : "0x",
    BASE_ROUTER_ADDR,
    inAmount,
    !!inToken
  );

  const swap = useCallback(
    async (
      inToken: Token,
      outToken: Token,
      inputAmount: W3Number,
      dryRun = false
    ) => {
      const executeSwapTx = async () => {
        // Test if the tx might fail with a dry run
        // If callStatic fails, it throws an error, and we let the user decide if they want to force it through
        if (dryRun) {
          await simulateContract(config, {
            abi: IDfxRouterAbi,
            address: BASE_ROUTER_ADDR,
            functionName: "originSwap",
            args: [
              inputAmount.big,
              0n,
              tokenRoute,
              BigInt(getDeadlineEpochTime(txSettings.minutesToTxDeadline)),
            ],
          });
          return null;
        } else {
          const reqHash = await adaptiveSendTransaction({
            to: BASE_ROUTER_ADDR,
            data: encodeFunctionData({
              abi: IDfxRouterAbi,
              functionName: "originSwap",
              args: [
                inputAmount.big,
                0n,
                tokenRoute,
                BigInt(getDeadlineEpochTime(txSettings.minutesToTxDeadline)),
              ],
            }),
          });
          await waitForTransactionReceipt(config, { hash: reqHash });
          toast({
            title: `Swap success`,
          });
        }
      };

      // Approve spending inToken
      console.log("approve required", approveRequired);
      if (approveRequired) {
        toast({ title: `Approving ${inToken.symbol}...` });
        const res = await executeApprove();
        if (res === null) return; // early exit: approval failed
        toast({
          title: "Approval successful! Proceeding with swap...",
        });

        await sleep(APPROVE_DELAY_MS); // X second delay
      }

      // Execute
      toast({
        title: `Swapping ${parseFloat(inputAmount.dsp.toPrecision(6))} ${
          inToken.symbol
        } for ${outToken.symbol}...`,
      });
      await executeSwapTx();
    },
    [
      adaptiveSendTransaction,
      approveRequired,
      executeApprove,
      tokenRoute,
      txSettings.minutesToTxDeadline,
    ]
  );

  return {
    minInAmount,
    minOutAmount,
    estimatedInAmount,
    estimatedOutAmount,
    spotPrice,
    swap,
    swapError,
  };
};

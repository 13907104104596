import { IDfxZapAbi } from "@/abi/IDfxZap";
import { IOracleAbi } from "@/abi/IOracle";
import { BASE_ZAP_ADDR } from "@/config/addresses";
import { config } from "@/config/config";
import { toW3Number, W3Number } from "@/lib/w3Num";
import { Pool } from "@/pages/Pools";
import { readContract } from "@wagmi/core";

export const calcMaxQuoteForDeposit = async (
  pool: Pool,
  amountA: W3Number,
  tokenBDec: number
) => {
  if (pool.reserveUsd > 0) {
    const [amountB, , lptAmount] = await readContract(config, {
      abi: IDfxZapAbi,
      address: BASE_ZAP_ADDR,
      functionName: "calcMaxQuoteForDeposit",
      args: [pool.id, amountA.big],
    });

    return {
      amountB: toW3Number(
        amountB,
        pool.baseToken.decimals,
        pool.baseToken.decimals
      ),
      lptAmount,
    };
  } else {
    const [, tokenAPerUSD] = await readContract(config, {
      abi: IOracleAbi,
      address: pool.quoteOracle,
      functionName: "latestRoundData",
    });
    const [, tokenBPerUSD] = await readContract(config, {
      abi: IOracleAbi,
      address: pool.baseOracle,
      functionName: "latestRoundData",
    });
    const amountB = (amountA.big * tokenAPerUSD) / tokenBPerUSD;
    const lptAmount = amountB * 2n * 10n ** BigInt(18 - tokenBDec);
    return {
      amountB: toW3Number(
        amountB,
        pool.baseToken.decimals,
        pool.baseToken.decimals
      ),
      lptAmount,
    };
  }
};
export const calcMaxBaseForDeposit = async (
  pool: Pool,
  amountB: W3Number,
  tokenBDec: number
) => {
  if (pool.reserveUsd > 0) {
    const [amountA, , lptAmount] = await readContract(config, {
      abi: IDfxZapAbi,
      address: BASE_ZAP_ADDR,
      functionName: "calcMaxBaseForDeposit",
      args: [pool.id, amountB.big],
    });
    return {
      amountA: toW3Number(
        amountA,
        pool.quoteToken.decimals,
        pool.quoteToken.decimals
      ),
      lptAmount,
    };
  } else {
    const [, tokenAPerUSD] = await readContract(config, {
      abi: IOracleAbi,
      address: pool.quoteOracle,
      functionName: "latestRoundData",
    });
    const [, tokenBPerUSD] = await readContract(config, {
      abi: IOracleAbi,
      address: pool.baseOracle,
      functionName: "latestRoundData",
    });
    const amountA = (amountB.big * tokenBPerUSD) / tokenAPerUSD;
    const lptAmount = amountB.big * 2n * 10n ** BigInt(18 - tokenBDec);
    return {
      amountA: toW3Number(
        amountA,
        pool.quoteToken.decimals,
        pool.quoteToken.decimals
      ),
      lptAmount,
    };
  }
};

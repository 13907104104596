import { PrivyClientConfig } from "@privy-io/react-auth";
import { createConfig } from "@privy-io/wagmi";
import { http } from "wagmi";
import { base, foundry, Chain } from "wagmi/chains";

export const BASE_SUBGRAPH = import.meta.env.VITE_BASE_SUBGRAPH_URL;

// Select chains depending on environment
const enabledChainsWagmi = import.meta.env.DEV
  ? ([base, foundry] as const)
  : ([base] as const);
const enabledChainsPrivy: Chain[] = [...enabledChainsWagmi];

// Privy config
export const privyConfig: PrivyClientConfig = {
  appearance: {
    theme: "dark",
    accentColor: "#FF8042",
    logo: "/assets/sedge.svg",
    showWalletLoginFirst: true,
  },
  loginMethods: ["wallet", "email", "sms"],
  defaultChain: base,
  supportedChains: enabledChainsPrivy,
  embeddedWallets: {
    createOnLogin: "users-without-wallets",
    noPromptOnSignature: true,
  },
};

// default wagmi config
export const config = createConfig({
  chains: enabledChainsWagmi,
  transports: {
    [base.id]: http(),
    [foundry.id]: http("http://127.0.0.1:8545"),
  },
});

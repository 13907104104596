import { useState } from "react";
import { CircleChevronDown, CircleChevronUp } from "lucide-react";

import { Button } from "@/components/ui/override/button";

interface Props {
  className?: string;
}
export const FlipIcon: React.FC<Props> = ({ className }) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <Button variant="ghost" onClick={() => setIsActive(!isActive)}>
      {isActive ? (
        <CircleChevronUp className={className} />
      ) : (
        <CircleChevronDown className={className} />
      )}
    </Button>
  );
};

import { Address } from "viem";

import { Button } from "@/components/ui/button";
import { Token } from "@/hooks/usePools";
import { PoolTitle } from "./pool-title";
import { MinusCircle, PlusCircle } from "lucide-react";

interface Props {
  id: Address;
  label: string;
  baseToken: Token;
  quoteToken: Token;
  totalValue: number;
  hasLpt: boolean;
  openAddLiquidityModal: () => void;
  openRemoveLiquidityModal: () => void;
  triggerButton: React.ReactNode;
}

const DefaultView: React.FC<Props> = ({
  id,
  baseToken,
  quoteToken,
  label,
  totalValue,
  hasLpt,
  openAddLiquidityModal,
  openRemoveLiquidityModal,
  triggerButton,
}) => {
  return (
    <div className="grid grid-cols-10 w-full">
      <div className="col-span-4 flex items-center">
        <PoolTitle
          id={id}
          pairLabel={label}
          baseIcon={baseToken.icon}
          quoteIcon={quoteToken.icon}
        />
      </div>
      <div className="col-span-2 flex items-center">
        <div className="flex flex-grow items-center justify-center font-medium text-lg">
          ${totalValue.toLocaleString()}
        </div>
      </div>
      <div className="col-span-4">
        <div className="flex flex-grow">
          <div className="flex flex-grow flex-col items-center justify-center gap-2">
            <Button
              onClick={openAddLiquidityModal}
              className="text-emerald-600 dark:text-green-200 hover:text-green-500 transition-colors"
              variant="ghost"
            >
              <PlusCircle />
              <span className="sm:hidden md:block">Add Liquidity</span>
              <span className="md:hidden">Add</span>
            </Button>
            {hasLpt && (
              <Button
                onClick={openRemoveLiquidityModal}
                className="text-rose-800 dark:text-red-200 hover:text-red-500 transition-colors"
                variant="ghost"
              >
                <MinusCircle />
                <span className="hidden md:block">Remove Liquidity</span>
                <span className="md:hidden">Remove</span>
              </Button>
            )}
          </div>
          <div className="flex items-center gap-2">{triggerButton}</div>
        </div>
      </div>
    </div>
  );
};

const MobileView: React.FC<Props> = ({
  id,
  baseToken,
  quoteToken,
  label,
  totalValue,
  hasLpt,
  openAddLiquidityModal,
  openRemoveLiquidityModal,
  triggerButton,
}) => {
  return (
    <>
      <div className="grid grid-cols-4 w-full">
        <div className="col-span-3 flex items-center">
          <PoolTitle
            id={id}
            pairLabel={label}
            baseIcon={baseToken.icon}
            quoteIcon={quoteToken.icon}
          />
        </div>
        <div className="flex items-center pr-4">
          <div className="flex flex-grow justify-end font-medium text-lg">
            ${totalValue.toLocaleString()}
          </div>
        </div>
      </div>

      <div className="flex sm:hidden gap-2 pt-4">
        <div className="flex flex-col">
          <div>
            <Button
              onClick={openAddLiquidityModal}
              className="text-emerald-600 dark:text-green-200 hover:text-green-500 transition-colors"
              variant="ghost"
            >
              <PlusCircle />
              <span>Add Liquidity</span>
            </Button>
          </div>
          {hasLpt && (
            <div>
              <Button
                onClick={openRemoveLiquidityModal}
                className="text-rose-800 dark:text-red-200 hover:text-red-500 transition-colors"
                variant="ghost"
              >
                <MinusCircle />
                <span>Remove Liquidity</span>
              </Button>
            </div>
          )}
        </div>
        <div className="flex md:hidden flex-grow justify-end items-end">
          {triggerButton}
        </div>
      </div>
    </>
  );
};

export const PoolRow: React.FC<Props> = ({
  id,
  baseToken,
  quoteToken,
  label,
  totalValue,
  hasLpt,
  openAddLiquidityModal,
  openRemoveLiquidityModal,
  triggerButton,
}) => {
  return (
    <>
      <div className="hidden sm:flex gap-2">
        <DefaultView
          id={id}
          label={label}
          baseToken={baseToken}
          quoteToken={quoteToken}
          totalValue={totalValue}
          hasLpt={hasLpt}
          openAddLiquidityModal={openAddLiquidityModal}
          openRemoveLiquidityModal={openRemoveLiquidityModal}
          triggerButton={triggerButton}
        />
      </div>

      <div className="flex flex-col sm:hidden">
        <MobileView
          id={id}
          label={label}
          baseToken={baseToken}
          quoteToken={quoteToken}
          totalValue={totalValue}
          hasLpt={hasLpt}
          openAddLiquidityModal={openAddLiquidityModal}
          openRemoveLiquidityModal={openRemoveLiquidityModal}
          triggerButton={triggerButton}
        />
      </div>
    </>
  );
};

export const IDfxRouterAbi = [
  {
    inputs: [{ internalType: "address", name: "_factory", type: "address" }],
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    inputs: [],
    name: "factory",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "uint256", name: "_originAmount", type: "uint256" },
      { internalType: "uint256", name: "_minTargetAmount", type: "uint256" },
      { internalType: "address[]", name: "_path", type: "address[]" },
      { internalType: "uint256", name: "_deadline", type: "uint256" },
    ],
    name: "originSwap",
    outputs: [
      { internalType: "uint256", name: "targetAmount_", type: "uint256" },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "uint256", name: "_minTargetAmount", type: "uint256" },
      { internalType: "address[]", name: "_path", type: "address[]" },
      { internalType: "uint256", name: "_deadline", type: "uint256" },
    ],
    name: "originSwapFromETH",
    outputs: [
      { internalType: "uint256", name: "targetAmount_", type: "uint256" },
    ],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "uint256", name: "_originAmount", type: "uint256" },
      { internalType: "uint256", name: "_minTargetAmount", type: "uint256" },
      { internalType: "address[]", name: "_path", type: "address[]" },
      { internalType: "uint256", name: "_deadline", type: "uint256" },
    ],
    name: "originSwapToETH",
    outputs: [
      { internalType: "uint256", name: "targetAmount_", type: "uint256" },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address[]", name: "_path", type: "address[]" },
      { internalType: "uint256", name: "_originAmount", type: "uint256" },
    ],
    name: "viewOriginSwap",
    outputs: [
      { internalType: "uint256", name: "targetAmount_", type: "uint256" },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "_quoteCurrency", type: "address" },
      { internalType: "address", name: "_origin", type: "address" },
      { internalType: "address", name: "_target", type: "address" },
      { internalType: "uint256", name: "_targetAmount", type: "uint256" },
    ],
    name: "viewTargetSwap",
    outputs: [
      { internalType: "uint256", name: "originAmount_", type: "uint256" },
    ],
    stateMutability: "view",
    type: "function",
  },
  { stateMutability: "payable", type: "receive" },
] as const;

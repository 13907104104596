import { ModeToggle } from "./mode-toggle";
import { LatestBlock } from "./latest-block";

export const Footer: React.FC = () => {
  return (
    <div className="flex-col">
      {/* Link Icons */}
      <div className="flex flex-col py-8">
        <div className="flex items-center justify-center gap-12">
          <ModeToggle />
        </div>
        <div className="flex mt-8 justify-center opacity-50">
          <LatestBlock />
        </div>
      </div>
    </div>
  );
};

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  BigDecimal: { input: any; output: any; }
  BigInt: { input: any; output: any; }
  Bytes: { input: any; output: any; }
  Int8: { input: any; output: any; }
  Timestamp: { input: any; output: any; }
};

export enum Aggregation_Interval {
  Day = 'day',
  Hour = 'hour'
}

export type Assimilator = {
  __typename?: 'Assimilator';
  id: Scalars['ID']['output'];
  oracle: Oracle;
  pairToken: Scalars['Bytes']['output'];
  token: Scalars['Bytes']['output'];
};

export type Assimilator_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Assimilator_Filter>>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  or?: InputMaybe<Array<InputMaybe<Assimilator_Filter>>>;
  oracle?: InputMaybe<Scalars['String']['input']>;
  oracle_?: InputMaybe<Oracle_Filter>;
  oracle_contains?: InputMaybe<Scalars['String']['input']>;
  oracle_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  oracle_ends_with?: InputMaybe<Scalars['String']['input']>;
  oracle_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  oracle_gt?: InputMaybe<Scalars['String']['input']>;
  oracle_gte?: InputMaybe<Scalars['String']['input']>;
  oracle_in?: InputMaybe<Array<Scalars['String']['input']>>;
  oracle_lt?: InputMaybe<Scalars['String']['input']>;
  oracle_lte?: InputMaybe<Scalars['String']['input']>;
  oracle_not?: InputMaybe<Scalars['String']['input']>;
  oracle_not_contains?: InputMaybe<Scalars['String']['input']>;
  oracle_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  oracle_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  oracle_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  oracle_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  oracle_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  oracle_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  oracle_starts_with?: InputMaybe<Scalars['String']['input']>;
  oracle_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  pairToken?: InputMaybe<Scalars['Bytes']['input']>;
  pairToken_contains?: InputMaybe<Scalars['Bytes']['input']>;
  pairToken_gt?: InputMaybe<Scalars['Bytes']['input']>;
  pairToken_gte?: InputMaybe<Scalars['Bytes']['input']>;
  pairToken_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  pairToken_lt?: InputMaybe<Scalars['Bytes']['input']>;
  pairToken_lte?: InputMaybe<Scalars['Bytes']['input']>;
  pairToken_not?: InputMaybe<Scalars['Bytes']['input']>;
  pairToken_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  pairToken_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  token?: InputMaybe<Scalars['Bytes']['input']>;
  token_contains?: InputMaybe<Scalars['Bytes']['input']>;
  token_gt?: InputMaybe<Scalars['Bytes']['input']>;
  token_gte?: InputMaybe<Scalars['Bytes']['input']>;
  token_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  token_lt?: InputMaybe<Scalars['Bytes']['input']>;
  token_lte?: InputMaybe<Scalars['Bytes']['input']>;
  token_not?: InputMaybe<Scalars['Bytes']['input']>;
  token_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  token_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
};

export enum Assimilator_OrderBy {
  Id = 'id',
  Oracle = 'oracle',
  OracleDecimals = 'oracle__decimals',
  OracleId = 'oracle__id',
  OracleToken = 'oracle__token',
  PairToken = 'pairToken',
  Token = 'token'
}

export type BlockChangedFilter = {
  number_gte: Scalars['Int']['input'];
};

export type Block_Height = {
  hash?: InputMaybe<Scalars['Bytes']['input']>;
  number?: InputMaybe<Scalars['Int']['input']>;
  number_gte?: InputMaybe<Scalars['Int']['input']>;
};

export type DfxDayData = {
  __typename?: 'DFXDayData';
  dailyFeeUSD: Scalars['BigDecimal']['output'];
  dailyVolumeUSD: Scalars['BigDecimal']['output'];
  date: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  totalFeeUSD: Scalars['BigDecimal']['output'];
  totalLiquidityUSD: Scalars['BigDecimal']['output'];
  totalVolumeUSD: Scalars['BigDecimal']['output'];
};

export type DfxDayData_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<DfxDayData_Filter>>>;
  dailyFeeUSD?: InputMaybe<Scalars['BigDecimal']['input']>;
  dailyFeeUSD_gt?: InputMaybe<Scalars['BigDecimal']['input']>;
  dailyFeeUSD_gte?: InputMaybe<Scalars['BigDecimal']['input']>;
  dailyFeeUSD_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  dailyFeeUSD_lt?: InputMaybe<Scalars['BigDecimal']['input']>;
  dailyFeeUSD_lte?: InputMaybe<Scalars['BigDecimal']['input']>;
  dailyFeeUSD_not?: InputMaybe<Scalars['BigDecimal']['input']>;
  dailyFeeUSD_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  dailyVolumeUSD?: InputMaybe<Scalars['BigDecimal']['input']>;
  dailyVolumeUSD_gt?: InputMaybe<Scalars['BigDecimal']['input']>;
  dailyVolumeUSD_gte?: InputMaybe<Scalars['BigDecimal']['input']>;
  dailyVolumeUSD_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  dailyVolumeUSD_lt?: InputMaybe<Scalars['BigDecimal']['input']>;
  dailyVolumeUSD_lte?: InputMaybe<Scalars['BigDecimal']['input']>;
  dailyVolumeUSD_not?: InputMaybe<Scalars['BigDecimal']['input']>;
  dailyVolumeUSD_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  date?: InputMaybe<Scalars['Int']['input']>;
  date_gt?: InputMaybe<Scalars['Int']['input']>;
  date_gte?: InputMaybe<Scalars['Int']['input']>;
  date_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  date_lt?: InputMaybe<Scalars['Int']['input']>;
  date_lte?: InputMaybe<Scalars['Int']['input']>;
  date_not?: InputMaybe<Scalars['Int']['input']>;
  date_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  or?: InputMaybe<Array<InputMaybe<DfxDayData_Filter>>>;
  totalFeeUSD?: InputMaybe<Scalars['BigDecimal']['input']>;
  totalFeeUSD_gt?: InputMaybe<Scalars['BigDecimal']['input']>;
  totalFeeUSD_gte?: InputMaybe<Scalars['BigDecimal']['input']>;
  totalFeeUSD_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  totalFeeUSD_lt?: InputMaybe<Scalars['BigDecimal']['input']>;
  totalFeeUSD_lte?: InputMaybe<Scalars['BigDecimal']['input']>;
  totalFeeUSD_not?: InputMaybe<Scalars['BigDecimal']['input']>;
  totalFeeUSD_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  totalLiquidityUSD?: InputMaybe<Scalars['BigDecimal']['input']>;
  totalLiquidityUSD_gt?: InputMaybe<Scalars['BigDecimal']['input']>;
  totalLiquidityUSD_gte?: InputMaybe<Scalars['BigDecimal']['input']>;
  totalLiquidityUSD_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  totalLiquidityUSD_lt?: InputMaybe<Scalars['BigDecimal']['input']>;
  totalLiquidityUSD_lte?: InputMaybe<Scalars['BigDecimal']['input']>;
  totalLiquidityUSD_not?: InputMaybe<Scalars['BigDecimal']['input']>;
  totalLiquidityUSD_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  totalVolumeUSD?: InputMaybe<Scalars['BigDecimal']['input']>;
  totalVolumeUSD_gt?: InputMaybe<Scalars['BigDecimal']['input']>;
  totalVolumeUSD_gte?: InputMaybe<Scalars['BigDecimal']['input']>;
  totalVolumeUSD_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  totalVolumeUSD_lt?: InputMaybe<Scalars['BigDecimal']['input']>;
  totalVolumeUSD_lte?: InputMaybe<Scalars['BigDecimal']['input']>;
  totalVolumeUSD_not?: InputMaybe<Scalars['BigDecimal']['input']>;
  totalVolumeUSD_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
};

export enum DfxDayData_OrderBy {
  DailyFeeUsd = 'dailyFeeUSD',
  DailyVolumeUsd = 'dailyVolumeUSD',
  Date = 'date',
  Id = 'id',
  TotalFeeUsd = 'totalFeeUSD',
  TotalLiquidityUsd = 'totalLiquidityUSD',
  TotalVolumeUsd = 'totalVolumeUSD'
}

export type DfxFactoryV3 = {
  __typename?: 'DFXFactoryV3';
  id: Scalars['ID']['output'];
  pairCount: Scalars['Int']['output'];
  totalFeeUSD: Scalars['BigDecimal']['output'];
  totalLiquidityUSD: Scalars['BigDecimal']['output'];
  totalVolumeUSD: Scalars['BigDecimal']['output'];
};

export type DfxFactoryV3_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<DfxFactoryV3_Filter>>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  or?: InputMaybe<Array<InputMaybe<DfxFactoryV3_Filter>>>;
  pairCount?: InputMaybe<Scalars['Int']['input']>;
  pairCount_gt?: InputMaybe<Scalars['Int']['input']>;
  pairCount_gte?: InputMaybe<Scalars['Int']['input']>;
  pairCount_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  pairCount_lt?: InputMaybe<Scalars['Int']['input']>;
  pairCount_lte?: InputMaybe<Scalars['Int']['input']>;
  pairCount_not?: InputMaybe<Scalars['Int']['input']>;
  pairCount_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  totalFeeUSD?: InputMaybe<Scalars['BigDecimal']['input']>;
  totalFeeUSD_gt?: InputMaybe<Scalars['BigDecimal']['input']>;
  totalFeeUSD_gte?: InputMaybe<Scalars['BigDecimal']['input']>;
  totalFeeUSD_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  totalFeeUSD_lt?: InputMaybe<Scalars['BigDecimal']['input']>;
  totalFeeUSD_lte?: InputMaybe<Scalars['BigDecimal']['input']>;
  totalFeeUSD_not?: InputMaybe<Scalars['BigDecimal']['input']>;
  totalFeeUSD_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  totalLiquidityUSD?: InputMaybe<Scalars['BigDecimal']['input']>;
  totalLiquidityUSD_gt?: InputMaybe<Scalars['BigDecimal']['input']>;
  totalLiquidityUSD_gte?: InputMaybe<Scalars['BigDecimal']['input']>;
  totalLiquidityUSD_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  totalLiquidityUSD_lt?: InputMaybe<Scalars['BigDecimal']['input']>;
  totalLiquidityUSD_lte?: InputMaybe<Scalars['BigDecimal']['input']>;
  totalLiquidityUSD_not?: InputMaybe<Scalars['BigDecimal']['input']>;
  totalLiquidityUSD_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  totalVolumeUSD?: InputMaybe<Scalars['BigDecimal']['input']>;
  totalVolumeUSD_gt?: InputMaybe<Scalars['BigDecimal']['input']>;
  totalVolumeUSD_gte?: InputMaybe<Scalars['BigDecimal']['input']>;
  totalVolumeUSD_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  totalVolumeUSD_lt?: InputMaybe<Scalars['BigDecimal']['input']>;
  totalVolumeUSD_lte?: InputMaybe<Scalars['BigDecimal']['input']>;
  totalVolumeUSD_not?: InputMaybe<Scalars['BigDecimal']['input']>;
  totalVolumeUSD_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
};

export enum DfxFactoryV3_OrderBy {
  Id = 'id',
  PairCount = 'pairCount',
  TotalFeeUsd = 'totalFeeUSD',
  TotalLiquidityUsd = 'totalLiquidityUSD',
  TotalVolumeUsd = 'totalVolumeUSD'
}

export type Oracle = {
  __typename?: 'Oracle';
  decimals: Scalars['BigInt']['output'];
  id: Scalars['ID']['output'];
  token: Scalars['Bytes']['output'];
};

export type Oracle_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Oracle_Filter>>>;
  decimals?: InputMaybe<Scalars['BigInt']['input']>;
  decimals_gt?: InputMaybe<Scalars['BigInt']['input']>;
  decimals_gte?: InputMaybe<Scalars['BigInt']['input']>;
  decimals_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  decimals_lt?: InputMaybe<Scalars['BigInt']['input']>;
  decimals_lte?: InputMaybe<Scalars['BigInt']['input']>;
  decimals_not?: InputMaybe<Scalars['BigInt']['input']>;
  decimals_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  or?: InputMaybe<Array<InputMaybe<Oracle_Filter>>>;
  token?: InputMaybe<Scalars['Bytes']['input']>;
  token_contains?: InputMaybe<Scalars['Bytes']['input']>;
  token_gt?: InputMaybe<Scalars['Bytes']['input']>;
  token_gte?: InputMaybe<Scalars['Bytes']['input']>;
  token_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  token_lt?: InputMaybe<Scalars['Bytes']['input']>;
  token_lte?: InputMaybe<Scalars['Bytes']['input']>;
  token_not?: InputMaybe<Scalars['Bytes']['input']>;
  token_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  token_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
};

export enum Oracle_OrderBy {
  Decimals = 'decimals',
  Id = 'id',
  Token = 'token'
}

/** Defines the order direction, either ascending or descending */
export enum OrderDirection {
  Asc = 'asc',
  Desc = 'desc'
}

export type Pair = {
  __typename?: 'Pair';
  alpha: Scalars['BigInt']['output'];
  assimilatorFrom0To1: Assimilator;
  assimilatorFrom1To0: Assimilator;
  beta: Scalars['BigInt']['output'];
  decimals: Scalars['BigInt']['output'];
  delta: Scalars['BigInt']['output'];
  dfxApproved: Scalars['Boolean']['output'];
  epsilon: Scalars['BigInt']['output'];
  hourData: Array<PairHourData>;
  id: Scalars['ID']['output'];
  lambda: Scalars['BigInt']['output'];
  name: Scalars['String']['output'];
  participantCount: Scalars['BigInt']['output'];
  protocolFee: Scalars['BigInt']['output'];
  reserve0: Scalars['BigDecimal']['output'];
  reserve0USD: Scalars['BigDecimal']['output'];
  reserve1: Scalars['BigDecimal']['output'];
  reserve1USD: Scalars['BigDecimal']['output'];
  reserveNative: Scalars['BigDecimal']['output'];
  reserveUSD: Scalars['BigDecimal']['output'];
  swapRateFrom0To1: Scalars['BigDecimal']['output'];
  swapRateFrom0To1USD: Scalars['BigDecimal']['output'];
  swapRateFrom1To0: Scalars['BigDecimal']['output'];
  swapRateFrom1To0USD: Scalars['BigDecimal']['output'];
  symbol: Scalars['String']['output'];
  token0: Token;
  token1: Token;
  totalFeeUSD: Scalars['BigDecimal']['output'];
  totalLPToken: Scalars['BigDecimal']['output'];
  txnsCount: Scalars['BigInt']['output'];
  volumeToken0: Scalars['BigDecimal']['output'];
  volumeToken0USD: Scalars['BigDecimal']['output'];
  volumeToken1: Scalars['BigDecimal']['output'];
  volumeToken1USD: Scalars['BigDecimal']['output'];
};


export type PairHourDataArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<PairHourData_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PairHourData_Filter>;
};

export type PairDayData = {
  __typename?: 'PairDayData';
  date: Scalars['Int']['output'];
  feeUSD: Scalars['BigDecimal']['output'];
  firstTxn: Scalars['Bytes']['output'];
  id: Scalars['ID']['output'];
  pair: Pair;
  reserve0: Scalars['BigDecimal']['output'];
  reserve0Deposit: Scalars['BigDecimal']['output'];
  reserve0DepositUSD: Scalars['BigDecimal']['output'];
  reserve0Withdraw: Scalars['BigDecimal']['output'];
  reserve0WithdrawUSD: Scalars['BigDecimal']['output'];
  reserve1: Scalars['BigDecimal']['output'];
  reserve1Deposit: Scalars['BigDecimal']['output'];
  reserve1DepositUSD: Scalars['BigDecimal']['output'];
  reserve1Withdraw: Scalars['BigDecimal']['output'];
  reserve1WithdrawUSD: Scalars['BigDecimal']['output'];
  reserveUSD: Scalars['BigDecimal']['output'];
  swapRateFrom0To1USD: Scalars['BigDecimal']['output'];
  swapRateFrom1To0USD: Scalars['BigDecimal']['output'];
  volumeToken0: Scalars['BigDecimal']['output'];
  volumeToken1: Scalars['BigDecimal']['output'];
  volumeUSD: Scalars['BigDecimal']['output'];
};

export type PairDayData_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<PairDayData_Filter>>>;
  date?: InputMaybe<Scalars['Int']['input']>;
  date_gt?: InputMaybe<Scalars['Int']['input']>;
  date_gte?: InputMaybe<Scalars['Int']['input']>;
  date_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  date_lt?: InputMaybe<Scalars['Int']['input']>;
  date_lte?: InputMaybe<Scalars['Int']['input']>;
  date_not?: InputMaybe<Scalars['Int']['input']>;
  date_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  feeUSD?: InputMaybe<Scalars['BigDecimal']['input']>;
  feeUSD_gt?: InputMaybe<Scalars['BigDecimal']['input']>;
  feeUSD_gte?: InputMaybe<Scalars['BigDecimal']['input']>;
  feeUSD_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  feeUSD_lt?: InputMaybe<Scalars['BigDecimal']['input']>;
  feeUSD_lte?: InputMaybe<Scalars['BigDecimal']['input']>;
  feeUSD_not?: InputMaybe<Scalars['BigDecimal']['input']>;
  feeUSD_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  firstTxn?: InputMaybe<Scalars['Bytes']['input']>;
  firstTxn_contains?: InputMaybe<Scalars['Bytes']['input']>;
  firstTxn_gt?: InputMaybe<Scalars['Bytes']['input']>;
  firstTxn_gte?: InputMaybe<Scalars['Bytes']['input']>;
  firstTxn_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  firstTxn_lt?: InputMaybe<Scalars['Bytes']['input']>;
  firstTxn_lte?: InputMaybe<Scalars['Bytes']['input']>;
  firstTxn_not?: InputMaybe<Scalars['Bytes']['input']>;
  firstTxn_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  firstTxn_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  or?: InputMaybe<Array<InputMaybe<PairDayData_Filter>>>;
  pair?: InputMaybe<Scalars['String']['input']>;
  pair_?: InputMaybe<Pair_Filter>;
  pair_contains?: InputMaybe<Scalars['String']['input']>;
  pair_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  pair_ends_with?: InputMaybe<Scalars['String']['input']>;
  pair_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  pair_gt?: InputMaybe<Scalars['String']['input']>;
  pair_gte?: InputMaybe<Scalars['String']['input']>;
  pair_in?: InputMaybe<Array<Scalars['String']['input']>>;
  pair_lt?: InputMaybe<Scalars['String']['input']>;
  pair_lte?: InputMaybe<Scalars['String']['input']>;
  pair_not?: InputMaybe<Scalars['String']['input']>;
  pair_not_contains?: InputMaybe<Scalars['String']['input']>;
  pair_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  pair_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  pair_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  pair_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  pair_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  pair_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  pair_starts_with?: InputMaybe<Scalars['String']['input']>;
  pair_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  reserve0?: InputMaybe<Scalars['BigDecimal']['input']>;
  reserve0Deposit?: InputMaybe<Scalars['BigDecimal']['input']>;
  reserve0DepositUSD?: InputMaybe<Scalars['BigDecimal']['input']>;
  reserve0DepositUSD_gt?: InputMaybe<Scalars['BigDecimal']['input']>;
  reserve0DepositUSD_gte?: InputMaybe<Scalars['BigDecimal']['input']>;
  reserve0DepositUSD_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  reserve0DepositUSD_lt?: InputMaybe<Scalars['BigDecimal']['input']>;
  reserve0DepositUSD_lte?: InputMaybe<Scalars['BigDecimal']['input']>;
  reserve0DepositUSD_not?: InputMaybe<Scalars['BigDecimal']['input']>;
  reserve0DepositUSD_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  reserve0Deposit_gt?: InputMaybe<Scalars['BigDecimal']['input']>;
  reserve0Deposit_gte?: InputMaybe<Scalars['BigDecimal']['input']>;
  reserve0Deposit_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  reserve0Deposit_lt?: InputMaybe<Scalars['BigDecimal']['input']>;
  reserve0Deposit_lte?: InputMaybe<Scalars['BigDecimal']['input']>;
  reserve0Deposit_not?: InputMaybe<Scalars['BigDecimal']['input']>;
  reserve0Deposit_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  reserve0Withdraw?: InputMaybe<Scalars['BigDecimal']['input']>;
  reserve0WithdrawUSD?: InputMaybe<Scalars['BigDecimal']['input']>;
  reserve0WithdrawUSD_gt?: InputMaybe<Scalars['BigDecimal']['input']>;
  reserve0WithdrawUSD_gte?: InputMaybe<Scalars['BigDecimal']['input']>;
  reserve0WithdrawUSD_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  reserve0WithdrawUSD_lt?: InputMaybe<Scalars['BigDecimal']['input']>;
  reserve0WithdrawUSD_lte?: InputMaybe<Scalars['BigDecimal']['input']>;
  reserve0WithdrawUSD_not?: InputMaybe<Scalars['BigDecimal']['input']>;
  reserve0WithdrawUSD_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  reserve0Withdraw_gt?: InputMaybe<Scalars['BigDecimal']['input']>;
  reserve0Withdraw_gte?: InputMaybe<Scalars['BigDecimal']['input']>;
  reserve0Withdraw_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  reserve0Withdraw_lt?: InputMaybe<Scalars['BigDecimal']['input']>;
  reserve0Withdraw_lte?: InputMaybe<Scalars['BigDecimal']['input']>;
  reserve0Withdraw_not?: InputMaybe<Scalars['BigDecimal']['input']>;
  reserve0Withdraw_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  reserve0_gt?: InputMaybe<Scalars['BigDecimal']['input']>;
  reserve0_gte?: InputMaybe<Scalars['BigDecimal']['input']>;
  reserve0_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  reserve0_lt?: InputMaybe<Scalars['BigDecimal']['input']>;
  reserve0_lte?: InputMaybe<Scalars['BigDecimal']['input']>;
  reserve0_not?: InputMaybe<Scalars['BigDecimal']['input']>;
  reserve0_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  reserve1?: InputMaybe<Scalars['BigDecimal']['input']>;
  reserve1Deposit?: InputMaybe<Scalars['BigDecimal']['input']>;
  reserve1DepositUSD?: InputMaybe<Scalars['BigDecimal']['input']>;
  reserve1DepositUSD_gt?: InputMaybe<Scalars['BigDecimal']['input']>;
  reserve1DepositUSD_gte?: InputMaybe<Scalars['BigDecimal']['input']>;
  reserve1DepositUSD_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  reserve1DepositUSD_lt?: InputMaybe<Scalars['BigDecimal']['input']>;
  reserve1DepositUSD_lte?: InputMaybe<Scalars['BigDecimal']['input']>;
  reserve1DepositUSD_not?: InputMaybe<Scalars['BigDecimal']['input']>;
  reserve1DepositUSD_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  reserve1Deposit_gt?: InputMaybe<Scalars['BigDecimal']['input']>;
  reserve1Deposit_gte?: InputMaybe<Scalars['BigDecimal']['input']>;
  reserve1Deposit_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  reserve1Deposit_lt?: InputMaybe<Scalars['BigDecimal']['input']>;
  reserve1Deposit_lte?: InputMaybe<Scalars['BigDecimal']['input']>;
  reserve1Deposit_not?: InputMaybe<Scalars['BigDecimal']['input']>;
  reserve1Deposit_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  reserve1Withdraw?: InputMaybe<Scalars['BigDecimal']['input']>;
  reserve1WithdrawUSD?: InputMaybe<Scalars['BigDecimal']['input']>;
  reserve1WithdrawUSD_gt?: InputMaybe<Scalars['BigDecimal']['input']>;
  reserve1WithdrawUSD_gte?: InputMaybe<Scalars['BigDecimal']['input']>;
  reserve1WithdrawUSD_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  reserve1WithdrawUSD_lt?: InputMaybe<Scalars['BigDecimal']['input']>;
  reserve1WithdrawUSD_lte?: InputMaybe<Scalars['BigDecimal']['input']>;
  reserve1WithdrawUSD_not?: InputMaybe<Scalars['BigDecimal']['input']>;
  reserve1WithdrawUSD_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  reserve1Withdraw_gt?: InputMaybe<Scalars['BigDecimal']['input']>;
  reserve1Withdraw_gte?: InputMaybe<Scalars['BigDecimal']['input']>;
  reserve1Withdraw_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  reserve1Withdraw_lt?: InputMaybe<Scalars['BigDecimal']['input']>;
  reserve1Withdraw_lte?: InputMaybe<Scalars['BigDecimal']['input']>;
  reserve1Withdraw_not?: InputMaybe<Scalars['BigDecimal']['input']>;
  reserve1Withdraw_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  reserve1_gt?: InputMaybe<Scalars['BigDecimal']['input']>;
  reserve1_gte?: InputMaybe<Scalars['BigDecimal']['input']>;
  reserve1_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  reserve1_lt?: InputMaybe<Scalars['BigDecimal']['input']>;
  reserve1_lte?: InputMaybe<Scalars['BigDecimal']['input']>;
  reserve1_not?: InputMaybe<Scalars['BigDecimal']['input']>;
  reserve1_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  reserveUSD?: InputMaybe<Scalars['BigDecimal']['input']>;
  reserveUSD_gt?: InputMaybe<Scalars['BigDecimal']['input']>;
  reserveUSD_gte?: InputMaybe<Scalars['BigDecimal']['input']>;
  reserveUSD_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  reserveUSD_lt?: InputMaybe<Scalars['BigDecimal']['input']>;
  reserveUSD_lte?: InputMaybe<Scalars['BigDecimal']['input']>;
  reserveUSD_not?: InputMaybe<Scalars['BigDecimal']['input']>;
  reserveUSD_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  swapRateFrom0To1USD?: InputMaybe<Scalars['BigDecimal']['input']>;
  swapRateFrom0To1USD_gt?: InputMaybe<Scalars['BigDecimal']['input']>;
  swapRateFrom0To1USD_gte?: InputMaybe<Scalars['BigDecimal']['input']>;
  swapRateFrom0To1USD_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  swapRateFrom0To1USD_lt?: InputMaybe<Scalars['BigDecimal']['input']>;
  swapRateFrom0To1USD_lte?: InputMaybe<Scalars['BigDecimal']['input']>;
  swapRateFrom0To1USD_not?: InputMaybe<Scalars['BigDecimal']['input']>;
  swapRateFrom0To1USD_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  swapRateFrom1To0USD?: InputMaybe<Scalars['BigDecimal']['input']>;
  swapRateFrom1To0USD_gt?: InputMaybe<Scalars['BigDecimal']['input']>;
  swapRateFrom1To0USD_gte?: InputMaybe<Scalars['BigDecimal']['input']>;
  swapRateFrom1To0USD_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  swapRateFrom1To0USD_lt?: InputMaybe<Scalars['BigDecimal']['input']>;
  swapRateFrom1To0USD_lte?: InputMaybe<Scalars['BigDecimal']['input']>;
  swapRateFrom1To0USD_not?: InputMaybe<Scalars['BigDecimal']['input']>;
  swapRateFrom1To0USD_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  volumeToken0?: InputMaybe<Scalars['BigDecimal']['input']>;
  volumeToken0_gt?: InputMaybe<Scalars['BigDecimal']['input']>;
  volumeToken0_gte?: InputMaybe<Scalars['BigDecimal']['input']>;
  volumeToken0_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  volumeToken0_lt?: InputMaybe<Scalars['BigDecimal']['input']>;
  volumeToken0_lte?: InputMaybe<Scalars['BigDecimal']['input']>;
  volumeToken0_not?: InputMaybe<Scalars['BigDecimal']['input']>;
  volumeToken0_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  volumeToken1?: InputMaybe<Scalars['BigDecimal']['input']>;
  volumeToken1_gt?: InputMaybe<Scalars['BigDecimal']['input']>;
  volumeToken1_gte?: InputMaybe<Scalars['BigDecimal']['input']>;
  volumeToken1_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  volumeToken1_lt?: InputMaybe<Scalars['BigDecimal']['input']>;
  volumeToken1_lte?: InputMaybe<Scalars['BigDecimal']['input']>;
  volumeToken1_not?: InputMaybe<Scalars['BigDecimal']['input']>;
  volumeToken1_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  volumeUSD?: InputMaybe<Scalars['BigDecimal']['input']>;
  volumeUSD_gt?: InputMaybe<Scalars['BigDecimal']['input']>;
  volumeUSD_gte?: InputMaybe<Scalars['BigDecimal']['input']>;
  volumeUSD_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  volumeUSD_lt?: InputMaybe<Scalars['BigDecimal']['input']>;
  volumeUSD_lte?: InputMaybe<Scalars['BigDecimal']['input']>;
  volumeUSD_not?: InputMaybe<Scalars['BigDecimal']['input']>;
  volumeUSD_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
};

export enum PairDayData_OrderBy {
  Date = 'date',
  FeeUsd = 'feeUSD',
  FirstTxn = 'firstTxn',
  Id = 'id',
  Pair = 'pair',
  PairAlpha = 'pair__alpha',
  PairBeta = 'pair__beta',
  PairDecimals = 'pair__decimals',
  PairDelta = 'pair__delta',
  PairDfxApproved = 'pair__dfxApproved',
  PairEpsilon = 'pair__epsilon',
  PairId = 'pair__id',
  PairLambda = 'pair__lambda',
  PairName = 'pair__name',
  PairParticipantCount = 'pair__participantCount',
  PairProtocolFee = 'pair__protocolFee',
  PairReserve0 = 'pair__reserve0',
  PairReserve0Usd = 'pair__reserve0USD',
  PairReserve1 = 'pair__reserve1',
  PairReserve1Usd = 'pair__reserve1USD',
  PairReserveNative = 'pair__reserveNative',
  PairReserveUsd = 'pair__reserveUSD',
  PairSwapRateFrom0To1 = 'pair__swapRateFrom0To1',
  PairSwapRateFrom0To1Usd = 'pair__swapRateFrom0To1USD',
  PairSwapRateFrom1To0 = 'pair__swapRateFrom1To0',
  PairSwapRateFrom1To0Usd = 'pair__swapRateFrom1To0USD',
  PairSymbol = 'pair__symbol',
  PairTotalFeeUsd = 'pair__totalFeeUSD',
  PairTotalLpToken = 'pair__totalLPToken',
  PairTxnsCount = 'pair__txnsCount',
  PairVolumeToken0 = 'pair__volumeToken0',
  PairVolumeToken0Usd = 'pair__volumeToken0USD',
  PairVolumeToken1 = 'pair__volumeToken1',
  PairVolumeToken1Usd = 'pair__volumeToken1USD',
  Reserve0 = 'reserve0',
  Reserve0Deposit = 'reserve0Deposit',
  Reserve0DepositUsd = 'reserve0DepositUSD',
  Reserve0Withdraw = 'reserve0Withdraw',
  Reserve0WithdrawUsd = 'reserve0WithdrawUSD',
  Reserve1 = 'reserve1',
  Reserve1Deposit = 'reserve1Deposit',
  Reserve1DepositUsd = 'reserve1DepositUSD',
  Reserve1Withdraw = 'reserve1Withdraw',
  Reserve1WithdrawUsd = 'reserve1WithdrawUSD',
  ReserveUsd = 'reserveUSD',
  SwapRateFrom0To1Usd = 'swapRateFrom0To1USD',
  SwapRateFrom1To0Usd = 'swapRateFrom1To0USD',
  VolumeToken0 = 'volumeToken0',
  VolumeToken1 = 'volumeToken1',
  VolumeUsd = 'volumeUSD'
}

export type PairHourData = {
  __typename?: 'PairHourData';
  firstTxn: Scalars['Bytes']['output'];
  hourStartUnix: Scalars['Int']['output'];
  hourVolumeToken0: Scalars['BigDecimal']['output'];
  hourVolumeToken1: Scalars['BigDecimal']['output'];
  id: Scalars['ID']['output'];
  maxRateFrom0To1: Scalars['BigDecimal']['output'];
  maxRateFrom1To0: Scalars['BigDecimal']['output'];
  minRateFrom0To1: Scalars['BigDecimal']['output'];
  minRateFrom1To0: Scalars['BigDecimal']['output'];
  pair: Pair;
  reserve0: Scalars['BigDecimal']['output'];
  reserve0USD: Scalars['BigDecimal']['output'];
  reserve1: Scalars['BigDecimal']['output'];
  reserve1USD: Scalars['BigDecimal']['output'];
  swapRateFrom0To1USD: Scalars['BigDecimal']['output'];
  swapRateFrom1To0USD: Scalars['BigDecimal']['output'];
  volumeToken0: Scalars['BigDecimal']['output'];
  volumeToken0USD: Scalars['BigDecimal']['output'];
  volumeToken1: Scalars['BigDecimal']['output'];
  volumeToken1USD: Scalars['BigDecimal']['output'];
  volumeUSD: Scalars['BigDecimal']['output'];
};

export type PairHourData_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<PairHourData_Filter>>>;
  firstTxn?: InputMaybe<Scalars['Bytes']['input']>;
  firstTxn_contains?: InputMaybe<Scalars['Bytes']['input']>;
  firstTxn_gt?: InputMaybe<Scalars['Bytes']['input']>;
  firstTxn_gte?: InputMaybe<Scalars['Bytes']['input']>;
  firstTxn_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  firstTxn_lt?: InputMaybe<Scalars['Bytes']['input']>;
  firstTxn_lte?: InputMaybe<Scalars['Bytes']['input']>;
  firstTxn_not?: InputMaybe<Scalars['Bytes']['input']>;
  firstTxn_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  firstTxn_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  hourStartUnix?: InputMaybe<Scalars['Int']['input']>;
  hourStartUnix_gt?: InputMaybe<Scalars['Int']['input']>;
  hourStartUnix_gte?: InputMaybe<Scalars['Int']['input']>;
  hourStartUnix_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  hourStartUnix_lt?: InputMaybe<Scalars['Int']['input']>;
  hourStartUnix_lte?: InputMaybe<Scalars['Int']['input']>;
  hourStartUnix_not?: InputMaybe<Scalars['Int']['input']>;
  hourStartUnix_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  hourVolumeToken0?: InputMaybe<Scalars['BigDecimal']['input']>;
  hourVolumeToken0_gt?: InputMaybe<Scalars['BigDecimal']['input']>;
  hourVolumeToken0_gte?: InputMaybe<Scalars['BigDecimal']['input']>;
  hourVolumeToken0_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  hourVolumeToken0_lt?: InputMaybe<Scalars['BigDecimal']['input']>;
  hourVolumeToken0_lte?: InputMaybe<Scalars['BigDecimal']['input']>;
  hourVolumeToken0_not?: InputMaybe<Scalars['BigDecimal']['input']>;
  hourVolumeToken0_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  hourVolumeToken1?: InputMaybe<Scalars['BigDecimal']['input']>;
  hourVolumeToken1_gt?: InputMaybe<Scalars['BigDecimal']['input']>;
  hourVolumeToken1_gte?: InputMaybe<Scalars['BigDecimal']['input']>;
  hourVolumeToken1_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  hourVolumeToken1_lt?: InputMaybe<Scalars['BigDecimal']['input']>;
  hourVolumeToken1_lte?: InputMaybe<Scalars['BigDecimal']['input']>;
  hourVolumeToken1_not?: InputMaybe<Scalars['BigDecimal']['input']>;
  hourVolumeToken1_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  maxRateFrom0To1?: InputMaybe<Scalars['BigDecimal']['input']>;
  maxRateFrom0To1_gt?: InputMaybe<Scalars['BigDecimal']['input']>;
  maxRateFrom0To1_gte?: InputMaybe<Scalars['BigDecimal']['input']>;
  maxRateFrom0To1_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  maxRateFrom0To1_lt?: InputMaybe<Scalars['BigDecimal']['input']>;
  maxRateFrom0To1_lte?: InputMaybe<Scalars['BigDecimal']['input']>;
  maxRateFrom0To1_not?: InputMaybe<Scalars['BigDecimal']['input']>;
  maxRateFrom0To1_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  maxRateFrom1To0?: InputMaybe<Scalars['BigDecimal']['input']>;
  maxRateFrom1To0_gt?: InputMaybe<Scalars['BigDecimal']['input']>;
  maxRateFrom1To0_gte?: InputMaybe<Scalars['BigDecimal']['input']>;
  maxRateFrom1To0_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  maxRateFrom1To0_lt?: InputMaybe<Scalars['BigDecimal']['input']>;
  maxRateFrom1To0_lte?: InputMaybe<Scalars['BigDecimal']['input']>;
  maxRateFrom1To0_not?: InputMaybe<Scalars['BigDecimal']['input']>;
  maxRateFrom1To0_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  minRateFrom0To1?: InputMaybe<Scalars['BigDecimal']['input']>;
  minRateFrom0To1_gt?: InputMaybe<Scalars['BigDecimal']['input']>;
  minRateFrom0To1_gte?: InputMaybe<Scalars['BigDecimal']['input']>;
  minRateFrom0To1_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  minRateFrom0To1_lt?: InputMaybe<Scalars['BigDecimal']['input']>;
  minRateFrom0To1_lte?: InputMaybe<Scalars['BigDecimal']['input']>;
  minRateFrom0To1_not?: InputMaybe<Scalars['BigDecimal']['input']>;
  minRateFrom0To1_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  minRateFrom1To0?: InputMaybe<Scalars['BigDecimal']['input']>;
  minRateFrom1To0_gt?: InputMaybe<Scalars['BigDecimal']['input']>;
  minRateFrom1To0_gte?: InputMaybe<Scalars['BigDecimal']['input']>;
  minRateFrom1To0_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  minRateFrom1To0_lt?: InputMaybe<Scalars['BigDecimal']['input']>;
  minRateFrom1To0_lte?: InputMaybe<Scalars['BigDecimal']['input']>;
  minRateFrom1To0_not?: InputMaybe<Scalars['BigDecimal']['input']>;
  minRateFrom1To0_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  or?: InputMaybe<Array<InputMaybe<PairHourData_Filter>>>;
  pair?: InputMaybe<Scalars['String']['input']>;
  pair_?: InputMaybe<Pair_Filter>;
  pair_contains?: InputMaybe<Scalars['String']['input']>;
  pair_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  pair_ends_with?: InputMaybe<Scalars['String']['input']>;
  pair_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  pair_gt?: InputMaybe<Scalars['String']['input']>;
  pair_gte?: InputMaybe<Scalars['String']['input']>;
  pair_in?: InputMaybe<Array<Scalars['String']['input']>>;
  pair_lt?: InputMaybe<Scalars['String']['input']>;
  pair_lte?: InputMaybe<Scalars['String']['input']>;
  pair_not?: InputMaybe<Scalars['String']['input']>;
  pair_not_contains?: InputMaybe<Scalars['String']['input']>;
  pair_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  pair_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  pair_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  pair_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  pair_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  pair_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  pair_starts_with?: InputMaybe<Scalars['String']['input']>;
  pair_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  reserve0?: InputMaybe<Scalars['BigDecimal']['input']>;
  reserve0USD?: InputMaybe<Scalars['BigDecimal']['input']>;
  reserve0USD_gt?: InputMaybe<Scalars['BigDecimal']['input']>;
  reserve0USD_gte?: InputMaybe<Scalars['BigDecimal']['input']>;
  reserve0USD_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  reserve0USD_lt?: InputMaybe<Scalars['BigDecimal']['input']>;
  reserve0USD_lte?: InputMaybe<Scalars['BigDecimal']['input']>;
  reserve0USD_not?: InputMaybe<Scalars['BigDecimal']['input']>;
  reserve0USD_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  reserve0_gt?: InputMaybe<Scalars['BigDecimal']['input']>;
  reserve0_gte?: InputMaybe<Scalars['BigDecimal']['input']>;
  reserve0_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  reserve0_lt?: InputMaybe<Scalars['BigDecimal']['input']>;
  reserve0_lte?: InputMaybe<Scalars['BigDecimal']['input']>;
  reserve0_not?: InputMaybe<Scalars['BigDecimal']['input']>;
  reserve0_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  reserve1?: InputMaybe<Scalars['BigDecimal']['input']>;
  reserve1USD?: InputMaybe<Scalars['BigDecimal']['input']>;
  reserve1USD_gt?: InputMaybe<Scalars['BigDecimal']['input']>;
  reserve1USD_gte?: InputMaybe<Scalars['BigDecimal']['input']>;
  reserve1USD_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  reserve1USD_lt?: InputMaybe<Scalars['BigDecimal']['input']>;
  reserve1USD_lte?: InputMaybe<Scalars['BigDecimal']['input']>;
  reserve1USD_not?: InputMaybe<Scalars['BigDecimal']['input']>;
  reserve1USD_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  reserve1_gt?: InputMaybe<Scalars['BigDecimal']['input']>;
  reserve1_gte?: InputMaybe<Scalars['BigDecimal']['input']>;
  reserve1_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  reserve1_lt?: InputMaybe<Scalars['BigDecimal']['input']>;
  reserve1_lte?: InputMaybe<Scalars['BigDecimal']['input']>;
  reserve1_not?: InputMaybe<Scalars['BigDecimal']['input']>;
  reserve1_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  swapRateFrom0To1USD?: InputMaybe<Scalars['BigDecimal']['input']>;
  swapRateFrom0To1USD_gt?: InputMaybe<Scalars['BigDecimal']['input']>;
  swapRateFrom0To1USD_gte?: InputMaybe<Scalars['BigDecimal']['input']>;
  swapRateFrom0To1USD_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  swapRateFrom0To1USD_lt?: InputMaybe<Scalars['BigDecimal']['input']>;
  swapRateFrom0To1USD_lte?: InputMaybe<Scalars['BigDecimal']['input']>;
  swapRateFrom0To1USD_not?: InputMaybe<Scalars['BigDecimal']['input']>;
  swapRateFrom0To1USD_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  swapRateFrom1To0USD?: InputMaybe<Scalars['BigDecimal']['input']>;
  swapRateFrom1To0USD_gt?: InputMaybe<Scalars['BigDecimal']['input']>;
  swapRateFrom1To0USD_gte?: InputMaybe<Scalars['BigDecimal']['input']>;
  swapRateFrom1To0USD_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  swapRateFrom1To0USD_lt?: InputMaybe<Scalars['BigDecimal']['input']>;
  swapRateFrom1To0USD_lte?: InputMaybe<Scalars['BigDecimal']['input']>;
  swapRateFrom1To0USD_not?: InputMaybe<Scalars['BigDecimal']['input']>;
  swapRateFrom1To0USD_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  volumeToken0?: InputMaybe<Scalars['BigDecimal']['input']>;
  volumeToken0USD?: InputMaybe<Scalars['BigDecimal']['input']>;
  volumeToken0USD_gt?: InputMaybe<Scalars['BigDecimal']['input']>;
  volumeToken0USD_gte?: InputMaybe<Scalars['BigDecimal']['input']>;
  volumeToken0USD_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  volumeToken0USD_lt?: InputMaybe<Scalars['BigDecimal']['input']>;
  volumeToken0USD_lte?: InputMaybe<Scalars['BigDecimal']['input']>;
  volumeToken0USD_not?: InputMaybe<Scalars['BigDecimal']['input']>;
  volumeToken0USD_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  volumeToken0_gt?: InputMaybe<Scalars['BigDecimal']['input']>;
  volumeToken0_gte?: InputMaybe<Scalars['BigDecimal']['input']>;
  volumeToken0_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  volumeToken0_lt?: InputMaybe<Scalars['BigDecimal']['input']>;
  volumeToken0_lte?: InputMaybe<Scalars['BigDecimal']['input']>;
  volumeToken0_not?: InputMaybe<Scalars['BigDecimal']['input']>;
  volumeToken0_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  volumeToken1?: InputMaybe<Scalars['BigDecimal']['input']>;
  volumeToken1USD?: InputMaybe<Scalars['BigDecimal']['input']>;
  volumeToken1USD_gt?: InputMaybe<Scalars['BigDecimal']['input']>;
  volumeToken1USD_gte?: InputMaybe<Scalars['BigDecimal']['input']>;
  volumeToken1USD_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  volumeToken1USD_lt?: InputMaybe<Scalars['BigDecimal']['input']>;
  volumeToken1USD_lte?: InputMaybe<Scalars['BigDecimal']['input']>;
  volumeToken1USD_not?: InputMaybe<Scalars['BigDecimal']['input']>;
  volumeToken1USD_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  volumeToken1_gt?: InputMaybe<Scalars['BigDecimal']['input']>;
  volumeToken1_gte?: InputMaybe<Scalars['BigDecimal']['input']>;
  volumeToken1_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  volumeToken1_lt?: InputMaybe<Scalars['BigDecimal']['input']>;
  volumeToken1_lte?: InputMaybe<Scalars['BigDecimal']['input']>;
  volumeToken1_not?: InputMaybe<Scalars['BigDecimal']['input']>;
  volumeToken1_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  volumeUSD?: InputMaybe<Scalars['BigDecimal']['input']>;
  volumeUSD_gt?: InputMaybe<Scalars['BigDecimal']['input']>;
  volumeUSD_gte?: InputMaybe<Scalars['BigDecimal']['input']>;
  volumeUSD_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  volumeUSD_lt?: InputMaybe<Scalars['BigDecimal']['input']>;
  volumeUSD_lte?: InputMaybe<Scalars['BigDecimal']['input']>;
  volumeUSD_not?: InputMaybe<Scalars['BigDecimal']['input']>;
  volumeUSD_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
};

export enum PairHourData_OrderBy {
  FirstTxn = 'firstTxn',
  HourStartUnix = 'hourStartUnix',
  HourVolumeToken0 = 'hourVolumeToken0',
  HourVolumeToken1 = 'hourVolumeToken1',
  Id = 'id',
  MaxRateFrom0To1 = 'maxRateFrom0To1',
  MaxRateFrom1To0 = 'maxRateFrom1To0',
  MinRateFrom0To1 = 'minRateFrom0To1',
  MinRateFrom1To0 = 'minRateFrom1To0',
  Pair = 'pair',
  PairAlpha = 'pair__alpha',
  PairBeta = 'pair__beta',
  PairDecimals = 'pair__decimals',
  PairDelta = 'pair__delta',
  PairDfxApproved = 'pair__dfxApproved',
  PairEpsilon = 'pair__epsilon',
  PairId = 'pair__id',
  PairLambda = 'pair__lambda',
  PairName = 'pair__name',
  PairParticipantCount = 'pair__participantCount',
  PairProtocolFee = 'pair__protocolFee',
  PairReserve0 = 'pair__reserve0',
  PairReserve0Usd = 'pair__reserve0USD',
  PairReserve1 = 'pair__reserve1',
  PairReserve1Usd = 'pair__reserve1USD',
  PairReserveNative = 'pair__reserveNative',
  PairReserveUsd = 'pair__reserveUSD',
  PairSwapRateFrom0To1 = 'pair__swapRateFrom0To1',
  PairSwapRateFrom0To1Usd = 'pair__swapRateFrom0To1USD',
  PairSwapRateFrom1To0 = 'pair__swapRateFrom1To0',
  PairSwapRateFrom1To0Usd = 'pair__swapRateFrom1To0USD',
  PairSymbol = 'pair__symbol',
  PairTotalFeeUsd = 'pair__totalFeeUSD',
  PairTotalLpToken = 'pair__totalLPToken',
  PairTxnsCount = 'pair__txnsCount',
  PairVolumeToken0 = 'pair__volumeToken0',
  PairVolumeToken0Usd = 'pair__volumeToken0USD',
  PairVolumeToken1 = 'pair__volumeToken1',
  PairVolumeToken1Usd = 'pair__volumeToken1USD',
  Reserve0 = 'reserve0',
  Reserve0Usd = 'reserve0USD',
  Reserve1 = 'reserve1',
  Reserve1Usd = 'reserve1USD',
  SwapRateFrom0To1Usd = 'swapRateFrom0To1USD',
  SwapRateFrom1To0Usd = 'swapRateFrom1To0USD',
  VolumeToken0 = 'volumeToken0',
  VolumeToken0Usd = 'volumeToken0USD',
  VolumeToken1 = 'volumeToken1',
  VolumeToken1Usd = 'volumeToken1USD',
  VolumeUsd = 'volumeUSD'
}

export type Pair_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  alpha?: InputMaybe<Scalars['BigInt']['input']>;
  alpha_gt?: InputMaybe<Scalars['BigInt']['input']>;
  alpha_gte?: InputMaybe<Scalars['BigInt']['input']>;
  alpha_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  alpha_lt?: InputMaybe<Scalars['BigInt']['input']>;
  alpha_lte?: InputMaybe<Scalars['BigInt']['input']>;
  alpha_not?: InputMaybe<Scalars['BigInt']['input']>;
  alpha_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  and?: InputMaybe<Array<InputMaybe<Pair_Filter>>>;
  assimilatorFrom0To1?: InputMaybe<Scalars['String']['input']>;
  assimilatorFrom0To1_?: InputMaybe<Assimilator_Filter>;
  assimilatorFrom0To1_contains?: InputMaybe<Scalars['String']['input']>;
  assimilatorFrom0To1_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  assimilatorFrom0To1_ends_with?: InputMaybe<Scalars['String']['input']>;
  assimilatorFrom0To1_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  assimilatorFrom0To1_gt?: InputMaybe<Scalars['String']['input']>;
  assimilatorFrom0To1_gte?: InputMaybe<Scalars['String']['input']>;
  assimilatorFrom0To1_in?: InputMaybe<Array<Scalars['String']['input']>>;
  assimilatorFrom0To1_lt?: InputMaybe<Scalars['String']['input']>;
  assimilatorFrom0To1_lte?: InputMaybe<Scalars['String']['input']>;
  assimilatorFrom0To1_not?: InputMaybe<Scalars['String']['input']>;
  assimilatorFrom0To1_not_contains?: InputMaybe<Scalars['String']['input']>;
  assimilatorFrom0To1_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  assimilatorFrom0To1_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  assimilatorFrom0To1_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  assimilatorFrom0To1_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  assimilatorFrom0To1_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  assimilatorFrom0To1_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  assimilatorFrom0To1_starts_with?: InputMaybe<Scalars['String']['input']>;
  assimilatorFrom0To1_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  assimilatorFrom1To0?: InputMaybe<Scalars['String']['input']>;
  assimilatorFrom1To0_?: InputMaybe<Assimilator_Filter>;
  assimilatorFrom1To0_contains?: InputMaybe<Scalars['String']['input']>;
  assimilatorFrom1To0_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  assimilatorFrom1To0_ends_with?: InputMaybe<Scalars['String']['input']>;
  assimilatorFrom1To0_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  assimilatorFrom1To0_gt?: InputMaybe<Scalars['String']['input']>;
  assimilatorFrom1To0_gte?: InputMaybe<Scalars['String']['input']>;
  assimilatorFrom1To0_in?: InputMaybe<Array<Scalars['String']['input']>>;
  assimilatorFrom1To0_lt?: InputMaybe<Scalars['String']['input']>;
  assimilatorFrom1To0_lte?: InputMaybe<Scalars['String']['input']>;
  assimilatorFrom1To0_not?: InputMaybe<Scalars['String']['input']>;
  assimilatorFrom1To0_not_contains?: InputMaybe<Scalars['String']['input']>;
  assimilatorFrom1To0_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  assimilatorFrom1To0_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  assimilatorFrom1To0_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  assimilatorFrom1To0_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  assimilatorFrom1To0_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  assimilatorFrom1To0_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  assimilatorFrom1To0_starts_with?: InputMaybe<Scalars['String']['input']>;
  assimilatorFrom1To0_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  beta?: InputMaybe<Scalars['BigInt']['input']>;
  beta_gt?: InputMaybe<Scalars['BigInt']['input']>;
  beta_gte?: InputMaybe<Scalars['BigInt']['input']>;
  beta_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  beta_lt?: InputMaybe<Scalars['BigInt']['input']>;
  beta_lte?: InputMaybe<Scalars['BigInt']['input']>;
  beta_not?: InputMaybe<Scalars['BigInt']['input']>;
  beta_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  decimals?: InputMaybe<Scalars['BigInt']['input']>;
  decimals_gt?: InputMaybe<Scalars['BigInt']['input']>;
  decimals_gte?: InputMaybe<Scalars['BigInt']['input']>;
  decimals_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  decimals_lt?: InputMaybe<Scalars['BigInt']['input']>;
  decimals_lte?: InputMaybe<Scalars['BigInt']['input']>;
  decimals_not?: InputMaybe<Scalars['BigInt']['input']>;
  decimals_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  delta?: InputMaybe<Scalars['BigInt']['input']>;
  delta_gt?: InputMaybe<Scalars['BigInt']['input']>;
  delta_gte?: InputMaybe<Scalars['BigInt']['input']>;
  delta_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  delta_lt?: InputMaybe<Scalars['BigInt']['input']>;
  delta_lte?: InputMaybe<Scalars['BigInt']['input']>;
  delta_not?: InputMaybe<Scalars['BigInt']['input']>;
  delta_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  dfxApproved?: InputMaybe<Scalars['Boolean']['input']>;
  dfxApproved_in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  dfxApproved_not?: InputMaybe<Scalars['Boolean']['input']>;
  dfxApproved_not_in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  epsilon?: InputMaybe<Scalars['BigInt']['input']>;
  epsilon_gt?: InputMaybe<Scalars['BigInt']['input']>;
  epsilon_gte?: InputMaybe<Scalars['BigInt']['input']>;
  epsilon_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  epsilon_lt?: InputMaybe<Scalars['BigInt']['input']>;
  epsilon_lte?: InputMaybe<Scalars['BigInt']['input']>;
  epsilon_not?: InputMaybe<Scalars['BigInt']['input']>;
  epsilon_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  hourData_?: InputMaybe<PairHourData_Filter>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  lambda?: InputMaybe<Scalars['BigInt']['input']>;
  lambda_gt?: InputMaybe<Scalars['BigInt']['input']>;
  lambda_gte?: InputMaybe<Scalars['BigInt']['input']>;
  lambda_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  lambda_lt?: InputMaybe<Scalars['BigInt']['input']>;
  lambda_lte?: InputMaybe<Scalars['BigInt']['input']>;
  lambda_not?: InputMaybe<Scalars['BigInt']['input']>;
  lambda_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  name_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_gt?: InputMaybe<Scalars['String']['input']>;
  name_gte?: InputMaybe<Scalars['String']['input']>;
  name_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_lt?: InputMaybe<Scalars['String']['input']>;
  name_lte?: InputMaybe<Scalars['String']['input']>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  or?: InputMaybe<Array<InputMaybe<Pair_Filter>>>;
  participantCount?: InputMaybe<Scalars['BigInt']['input']>;
  participantCount_gt?: InputMaybe<Scalars['BigInt']['input']>;
  participantCount_gte?: InputMaybe<Scalars['BigInt']['input']>;
  participantCount_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  participantCount_lt?: InputMaybe<Scalars['BigInt']['input']>;
  participantCount_lte?: InputMaybe<Scalars['BigInt']['input']>;
  participantCount_not?: InputMaybe<Scalars['BigInt']['input']>;
  participantCount_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  protocolFee?: InputMaybe<Scalars['BigInt']['input']>;
  protocolFee_gt?: InputMaybe<Scalars['BigInt']['input']>;
  protocolFee_gte?: InputMaybe<Scalars['BigInt']['input']>;
  protocolFee_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  protocolFee_lt?: InputMaybe<Scalars['BigInt']['input']>;
  protocolFee_lte?: InputMaybe<Scalars['BigInt']['input']>;
  protocolFee_not?: InputMaybe<Scalars['BigInt']['input']>;
  protocolFee_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  reserve0?: InputMaybe<Scalars['BigDecimal']['input']>;
  reserve0USD?: InputMaybe<Scalars['BigDecimal']['input']>;
  reserve0USD_gt?: InputMaybe<Scalars['BigDecimal']['input']>;
  reserve0USD_gte?: InputMaybe<Scalars['BigDecimal']['input']>;
  reserve0USD_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  reserve0USD_lt?: InputMaybe<Scalars['BigDecimal']['input']>;
  reserve0USD_lte?: InputMaybe<Scalars['BigDecimal']['input']>;
  reserve0USD_not?: InputMaybe<Scalars['BigDecimal']['input']>;
  reserve0USD_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  reserve0_gt?: InputMaybe<Scalars['BigDecimal']['input']>;
  reserve0_gte?: InputMaybe<Scalars['BigDecimal']['input']>;
  reserve0_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  reserve0_lt?: InputMaybe<Scalars['BigDecimal']['input']>;
  reserve0_lte?: InputMaybe<Scalars['BigDecimal']['input']>;
  reserve0_not?: InputMaybe<Scalars['BigDecimal']['input']>;
  reserve0_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  reserve1?: InputMaybe<Scalars['BigDecimal']['input']>;
  reserve1USD?: InputMaybe<Scalars['BigDecimal']['input']>;
  reserve1USD_gt?: InputMaybe<Scalars['BigDecimal']['input']>;
  reserve1USD_gte?: InputMaybe<Scalars['BigDecimal']['input']>;
  reserve1USD_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  reserve1USD_lt?: InputMaybe<Scalars['BigDecimal']['input']>;
  reserve1USD_lte?: InputMaybe<Scalars['BigDecimal']['input']>;
  reserve1USD_not?: InputMaybe<Scalars['BigDecimal']['input']>;
  reserve1USD_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  reserve1_gt?: InputMaybe<Scalars['BigDecimal']['input']>;
  reserve1_gte?: InputMaybe<Scalars['BigDecimal']['input']>;
  reserve1_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  reserve1_lt?: InputMaybe<Scalars['BigDecimal']['input']>;
  reserve1_lte?: InputMaybe<Scalars['BigDecimal']['input']>;
  reserve1_not?: InputMaybe<Scalars['BigDecimal']['input']>;
  reserve1_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  reserveNative?: InputMaybe<Scalars['BigDecimal']['input']>;
  reserveNative_gt?: InputMaybe<Scalars['BigDecimal']['input']>;
  reserveNative_gte?: InputMaybe<Scalars['BigDecimal']['input']>;
  reserveNative_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  reserveNative_lt?: InputMaybe<Scalars['BigDecimal']['input']>;
  reserveNative_lte?: InputMaybe<Scalars['BigDecimal']['input']>;
  reserveNative_not?: InputMaybe<Scalars['BigDecimal']['input']>;
  reserveNative_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  reserveUSD?: InputMaybe<Scalars['BigDecimal']['input']>;
  reserveUSD_gt?: InputMaybe<Scalars['BigDecimal']['input']>;
  reserveUSD_gte?: InputMaybe<Scalars['BigDecimal']['input']>;
  reserveUSD_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  reserveUSD_lt?: InputMaybe<Scalars['BigDecimal']['input']>;
  reserveUSD_lte?: InputMaybe<Scalars['BigDecimal']['input']>;
  reserveUSD_not?: InputMaybe<Scalars['BigDecimal']['input']>;
  reserveUSD_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  swapRateFrom0To1?: InputMaybe<Scalars['BigDecimal']['input']>;
  swapRateFrom0To1USD?: InputMaybe<Scalars['BigDecimal']['input']>;
  swapRateFrom0To1USD_gt?: InputMaybe<Scalars['BigDecimal']['input']>;
  swapRateFrom0To1USD_gte?: InputMaybe<Scalars['BigDecimal']['input']>;
  swapRateFrom0To1USD_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  swapRateFrom0To1USD_lt?: InputMaybe<Scalars['BigDecimal']['input']>;
  swapRateFrom0To1USD_lte?: InputMaybe<Scalars['BigDecimal']['input']>;
  swapRateFrom0To1USD_not?: InputMaybe<Scalars['BigDecimal']['input']>;
  swapRateFrom0To1USD_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  swapRateFrom0To1_gt?: InputMaybe<Scalars['BigDecimal']['input']>;
  swapRateFrom0To1_gte?: InputMaybe<Scalars['BigDecimal']['input']>;
  swapRateFrom0To1_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  swapRateFrom0To1_lt?: InputMaybe<Scalars['BigDecimal']['input']>;
  swapRateFrom0To1_lte?: InputMaybe<Scalars['BigDecimal']['input']>;
  swapRateFrom0To1_not?: InputMaybe<Scalars['BigDecimal']['input']>;
  swapRateFrom0To1_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  swapRateFrom1To0?: InputMaybe<Scalars['BigDecimal']['input']>;
  swapRateFrom1To0USD?: InputMaybe<Scalars['BigDecimal']['input']>;
  swapRateFrom1To0USD_gt?: InputMaybe<Scalars['BigDecimal']['input']>;
  swapRateFrom1To0USD_gte?: InputMaybe<Scalars['BigDecimal']['input']>;
  swapRateFrom1To0USD_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  swapRateFrom1To0USD_lt?: InputMaybe<Scalars['BigDecimal']['input']>;
  swapRateFrom1To0USD_lte?: InputMaybe<Scalars['BigDecimal']['input']>;
  swapRateFrom1To0USD_not?: InputMaybe<Scalars['BigDecimal']['input']>;
  swapRateFrom1To0USD_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  swapRateFrom1To0_gt?: InputMaybe<Scalars['BigDecimal']['input']>;
  swapRateFrom1To0_gte?: InputMaybe<Scalars['BigDecimal']['input']>;
  swapRateFrom1To0_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  swapRateFrom1To0_lt?: InputMaybe<Scalars['BigDecimal']['input']>;
  swapRateFrom1To0_lte?: InputMaybe<Scalars['BigDecimal']['input']>;
  swapRateFrom1To0_not?: InputMaybe<Scalars['BigDecimal']['input']>;
  swapRateFrom1To0_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  symbol?: InputMaybe<Scalars['String']['input']>;
  symbol_contains?: InputMaybe<Scalars['String']['input']>;
  symbol_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  symbol_ends_with?: InputMaybe<Scalars['String']['input']>;
  symbol_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  symbol_gt?: InputMaybe<Scalars['String']['input']>;
  symbol_gte?: InputMaybe<Scalars['String']['input']>;
  symbol_in?: InputMaybe<Array<Scalars['String']['input']>>;
  symbol_lt?: InputMaybe<Scalars['String']['input']>;
  symbol_lte?: InputMaybe<Scalars['String']['input']>;
  symbol_not?: InputMaybe<Scalars['String']['input']>;
  symbol_not_contains?: InputMaybe<Scalars['String']['input']>;
  symbol_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  symbol_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  symbol_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  symbol_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  symbol_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  symbol_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  symbol_starts_with?: InputMaybe<Scalars['String']['input']>;
  symbol_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  token0?: InputMaybe<Scalars['String']['input']>;
  token0_?: InputMaybe<Token_Filter>;
  token0_contains?: InputMaybe<Scalars['String']['input']>;
  token0_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  token0_ends_with?: InputMaybe<Scalars['String']['input']>;
  token0_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  token0_gt?: InputMaybe<Scalars['String']['input']>;
  token0_gte?: InputMaybe<Scalars['String']['input']>;
  token0_in?: InputMaybe<Array<Scalars['String']['input']>>;
  token0_lt?: InputMaybe<Scalars['String']['input']>;
  token0_lte?: InputMaybe<Scalars['String']['input']>;
  token0_not?: InputMaybe<Scalars['String']['input']>;
  token0_not_contains?: InputMaybe<Scalars['String']['input']>;
  token0_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  token0_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  token0_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  token0_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  token0_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  token0_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  token0_starts_with?: InputMaybe<Scalars['String']['input']>;
  token0_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  token1?: InputMaybe<Scalars['String']['input']>;
  token1_?: InputMaybe<Token_Filter>;
  token1_contains?: InputMaybe<Scalars['String']['input']>;
  token1_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  token1_ends_with?: InputMaybe<Scalars['String']['input']>;
  token1_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  token1_gt?: InputMaybe<Scalars['String']['input']>;
  token1_gte?: InputMaybe<Scalars['String']['input']>;
  token1_in?: InputMaybe<Array<Scalars['String']['input']>>;
  token1_lt?: InputMaybe<Scalars['String']['input']>;
  token1_lte?: InputMaybe<Scalars['String']['input']>;
  token1_not?: InputMaybe<Scalars['String']['input']>;
  token1_not_contains?: InputMaybe<Scalars['String']['input']>;
  token1_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  token1_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  token1_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  token1_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  token1_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  token1_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  token1_starts_with?: InputMaybe<Scalars['String']['input']>;
  token1_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  totalFeeUSD?: InputMaybe<Scalars['BigDecimal']['input']>;
  totalFeeUSD_gt?: InputMaybe<Scalars['BigDecimal']['input']>;
  totalFeeUSD_gte?: InputMaybe<Scalars['BigDecimal']['input']>;
  totalFeeUSD_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  totalFeeUSD_lt?: InputMaybe<Scalars['BigDecimal']['input']>;
  totalFeeUSD_lte?: InputMaybe<Scalars['BigDecimal']['input']>;
  totalFeeUSD_not?: InputMaybe<Scalars['BigDecimal']['input']>;
  totalFeeUSD_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  totalLPToken?: InputMaybe<Scalars['BigDecimal']['input']>;
  totalLPToken_gt?: InputMaybe<Scalars['BigDecimal']['input']>;
  totalLPToken_gte?: InputMaybe<Scalars['BigDecimal']['input']>;
  totalLPToken_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  totalLPToken_lt?: InputMaybe<Scalars['BigDecimal']['input']>;
  totalLPToken_lte?: InputMaybe<Scalars['BigDecimal']['input']>;
  totalLPToken_not?: InputMaybe<Scalars['BigDecimal']['input']>;
  totalLPToken_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  txnsCount?: InputMaybe<Scalars['BigInt']['input']>;
  txnsCount_gt?: InputMaybe<Scalars['BigInt']['input']>;
  txnsCount_gte?: InputMaybe<Scalars['BigInt']['input']>;
  txnsCount_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  txnsCount_lt?: InputMaybe<Scalars['BigInt']['input']>;
  txnsCount_lte?: InputMaybe<Scalars['BigInt']['input']>;
  txnsCount_not?: InputMaybe<Scalars['BigInt']['input']>;
  txnsCount_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  volumeToken0?: InputMaybe<Scalars['BigDecimal']['input']>;
  volumeToken0USD?: InputMaybe<Scalars['BigDecimal']['input']>;
  volumeToken0USD_gt?: InputMaybe<Scalars['BigDecimal']['input']>;
  volumeToken0USD_gte?: InputMaybe<Scalars['BigDecimal']['input']>;
  volumeToken0USD_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  volumeToken0USD_lt?: InputMaybe<Scalars['BigDecimal']['input']>;
  volumeToken0USD_lte?: InputMaybe<Scalars['BigDecimal']['input']>;
  volumeToken0USD_not?: InputMaybe<Scalars['BigDecimal']['input']>;
  volumeToken0USD_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  volumeToken0_gt?: InputMaybe<Scalars['BigDecimal']['input']>;
  volumeToken0_gte?: InputMaybe<Scalars['BigDecimal']['input']>;
  volumeToken0_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  volumeToken0_lt?: InputMaybe<Scalars['BigDecimal']['input']>;
  volumeToken0_lte?: InputMaybe<Scalars['BigDecimal']['input']>;
  volumeToken0_not?: InputMaybe<Scalars['BigDecimal']['input']>;
  volumeToken0_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  volumeToken1?: InputMaybe<Scalars['BigDecimal']['input']>;
  volumeToken1USD?: InputMaybe<Scalars['BigDecimal']['input']>;
  volumeToken1USD_gt?: InputMaybe<Scalars['BigDecimal']['input']>;
  volumeToken1USD_gte?: InputMaybe<Scalars['BigDecimal']['input']>;
  volumeToken1USD_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  volumeToken1USD_lt?: InputMaybe<Scalars['BigDecimal']['input']>;
  volumeToken1USD_lte?: InputMaybe<Scalars['BigDecimal']['input']>;
  volumeToken1USD_not?: InputMaybe<Scalars['BigDecimal']['input']>;
  volumeToken1USD_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  volumeToken1_gt?: InputMaybe<Scalars['BigDecimal']['input']>;
  volumeToken1_gte?: InputMaybe<Scalars['BigDecimal']['input']>;
  volumeToken1_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  volumeToken1_lt?: InputMaybe<Scalars['BigDecimal']['input']>;
  volumeToken1_lte?: InputMaybe<Scalars['BigDecimal']['input']>;
  volumeToken1_not?: InputMaybe<Scalars['BigDecimal']['input']>;
  volumeToken1_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
};

export enum Pair_OrderBy {
  Alpha = 'alpha',
  AssimilatorFrom0To1 = 'assimilatorFrom0To1',
  AssimilatorFrom0To1Id = 'assimilatorFrom0To1__id',
  AssimilatorFrom0To1PairToken = 'assimilatorFrom0To1__pairToken',
  AssimilatorFrom0To1Token = 'assimilatorFrom0To1__token',
  AssimilatorFrom1To0 = 'assimilatorFrom1To0',
  AssimilatorFrom1To0Id = 'assimilatorFrom1To0__id',
  AssimilatorFrom1To0PairToken = 'assimilatorFrom1To0__pairToken',
  AssimilatorFrom1To0Token = 'assimilatorFrom1To0__token',
  Beta = 'beta',
  Decimals = 'decimals',
  Delta = 'delta',
  DfxApproved = 'dfxApproved',
  Epsilon = 'epsilon',
  HourData = 'hourData',
  Id = 'id',
  Lambda = 'lambda',
  Name = 'name',
  ParticipantCount = 'participantCount',
  ProtocolFee = 'protocolFee',
  Reserve0 = 'reserve0',
  Reserve0Usd = 'reserve0USD',
  Reserve1 = 'reserve1',
  Reserve1Usd = 'reserve1USD',
  ReserveNative = 'reserveNative',
  ReserveUsd = 'reserveUSD',
  SwapRateFrom0To1 = 'swapRateFrom0To1',
  SwapRateFrom0To1Usd = 'swapRateFrom0To1USD',
  SwapRateFrom1To0 = 'swapRateFrom1To0',
  SwapRateFrom1To0Usd = 'swapRateFrom1To0USD',
  Symbol = 'symbol',
  Token0 = 'token0',
  Token0Decimals = 'token0__decimals',
  Token0Id = 'token0__id',
  Token0Name = 'token0__name',
  Token0PriceUsd = 'token0__priceUSD',
  Token0Symbol = 'token0__symbol',
  Token1 = 'token1',
  Token1Decimals = 'token1__decimals',
  Token1Id = 'token1__id',
  Token1Name = 'token1__name',
  Token1PriceUsd = 'token1__priceUSD',
  Token1Symbol = 'token1__symbol',
  TotalFeeUsd = 'totalFeeUSD',
  TotalLpToken = 'totalLPToken',
  TxnsCount = 'txnsCount',
  VolumeToken0 = 'volumeToken0',
  VolumeToken0Usd = 'volumeToken0USD',
  VolumeToken1 = 'volumeToken1',
  VolumeToken1Usd = 'volumeToken1USD'
}

export type PoolParticipant = {
  __typename?: 'PoolParticipant';
  id: Scalars['ID']['output'];
  liquidityProvided: Scalars['BigDecimal']['output'];
  lpTokenBalance: Scalars['BigDecimal']['output'];
  pair: Pair;
  participant: Scalars['Bytes']['output'];
};

export type PoolParticipant_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<PoolParticipant_Filter>>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  liquidityProvided?: InputMaybe<Scalars['BigDecimal']['input']>;
  liquidityProvided_gt?: InputMaybe<Scalars['BigDecimal']['input']>;
  liquidityProvided_gte?: InputMaybe<Scalars['BigDecimal']['input']>;
  liquidityProvided_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  liquidityProvided_lt?: InputMaybe<Scalars['BigDecimal']['input']>;
  liquidityProvided_lte?: InputMaybe<Scalars['BigDecimal']['input']>;
  liquidityProvided_not?: InputMaybe<Scalars['BigDecimal']['input']>;
  liquidityProvided_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  lpTokenBalance?: InputMaybe<Scalars['BigDecimal']['input']>;
  lpTokenBalance_gt?: InputMaybe<Scalars['BigDecimal']['input']>;
  lpTokenBalance_gte?: InputMaybe<Scalars['BigDecimal']['input']>;
  lpTokenBalance_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  lpTokenBalance_lt?: InputMaybe<Scalars['BigDecimal']['input']>;
  lpTokenBalance_lte?: InputMaybe<Scalars['BigDecimal']['input']>;
  lpTokenBalance_not?: InputMaybe<Scalars['BigDecimal']['input']>;
  lpTokenBalance_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  or?: InputMaybe<Array<InputMaybe<PoolParticipant_Filter>>>;
  pair?: InputMaybe<Scalars['String']['input']>;
  pair_?: InputMaybe<Pair_Filter>;
  pair_contains?: InputMaybe<Scalars['String']['input']>;
  pair_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  pair_ends_with?: InputMaybe<Scalars['String']['input']>;
  pair_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  pair_gt?: InputMaybe<Scalars['String']['input']>;
  pair_gte?: InputMaybe<Scalars['String']['input']>;
  pair_in?: InputMaybe<Array<Scalars['String']['input']>>;
  pair_lt?: InputMaybe<Scalars['String']['input']>;
  pair_lte?: InputMaybe<Scalars['String']['input']>;
  pair_not?: InputMaybe<Scalars['String']['input']>;
  pair_not_contains?: InputMaybe<Scalars['String']['input']>;
  pair_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  pair_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  pair_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  pair_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  pair_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  pair_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  pair_starts_with?: InputMaybe<Scalars['String']['input']>;
  pair_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  participant?: InputMaybe<Scalars['Bytes']['input']>;
  participant_contains?: InputMaybe<Scalars['Bytes']['input']>;
  participant_gt?: InputMaybe<Scalars['Bytes']['input']>;
  participant_gte?: InputMaybe<Scalars['Bytes']['input']>;
  participant_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  participant_lt?: InputMaybe<Scalars['Bytes']['input']>;
  participant_lte?: InputMaybe<Scalars['Bytes']['input']>;
  participant_not?: InputMaybe<Scalars['Bytes']['input']>;
  participant_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  participant_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
};

export enum PoolParticipant_OrderBy {
  Id = 'id',
  LiquidityProvided = 'liquidityProvided',
  LpTokenBalance = 'lpTokenBalance',
  Pair = 'pair',
  PairAlpha = 'pair__alpha',
  PairBeta = 'pair__beta',
  PairDecimals = 'pair__decimals',
  PairDelta = 'pair__delta',
  PairDfxApproved = 'pair__dfxApproved',
  PairEpsilon = 'pair__epsilon',
  PairId = 'pair__id',
  PairLambda = 'pair__lambda',
  PairName = 'pair__name',
  PairParticipantCount = 'pair__participantCount',
  PairProtocolFee = 'pair__protocolFee',
  PairReserve0 = 'pair__reserve0',
  PairReserve0Usd = 'pair__reserve0USD',
  PairReserve1 = 'pair__reserve1',
  PairReserve1Usd = 'pair__reserve1USD',
  PairReserveNative = 'pair__reserveNative',
  PairReserveUsd = 'pair__reserveUSD',
  PairSwapRateFrom0To1 = 'pair__swapRateFrom0To1',
  PairSwapRateFrom0To1Usd = 'pair__swapRateFrom0To1USD',
  PairSwapRateFrom1To0 = 'pair__swapRateFrom1To0',
  PairSwapRateFrom1To0Usd = 'pair__swapRateFrom1To0USD',
  PairSymbol = 'pair__symbol',
  PairTotalFeeUsd = 'pair__totalFeeUSD',
  PairTotalLpToken = 'pair__totalLPToken',
  PairTxnsCount = 'pair__txnsCount',
  PairVolumeToken0 = 'pair__volumeToken0',
  PairVolumeToken0Usd = 'pair__volumeToken0USD',
  PairVolumeToken1 = 'pair__volumeToken1',
  PairVolumeToken1Usd = 'pair__volumeToken1USD',
  Participant = 'participant'
}

export type Query = {
  __typename?: 'Query';
  /** Access to subgraph metadata */
  _meta?: Maybe<_Meta_>;
  assimilator?: Maybe<Assimilator>;
  assimilators: Array<Assimilator>;
  dfxdayData?: Maybe<DfxDayData>;
  dfxdayDatas: Array<DfxDayData>;
  dfxfactoryV3?: Maybe<DfxFactoryV3>;
  dfxfactoryV3S: Array<DfxFactoryV3>;
  oracle?: Maybe<Oracle>;
  oracles: Array<Oracle>;
  pair?: Maybe<Pair>;
  pairDayData?: Maybe<PairDayData>;
  pairDayDatas: Array<PairDayData>;
  pairHourData?: Maybe<PairHourData>;
  pairHourDatas: Array<PairHourData>;
  pairs: Array<Pair>;
  poolParticipant?: Maybe<PoolParticipant>;
  poolParticipants: Array<PoolParticipant>;
  token?: Maybe<Token>;
  tokenDayData?: Maybe<TokenDayData>;
  tokenDayDatas: Array<TokenDayData>;
  tokens: Array<Token>;
  trade?: Maybe<Trade>;
  trades: Array<Trade>;
  transfer?: Maybe<Transfer>;
  transfers: Array<Transfer>;
};


export type Query_MetaArgs = {
  block?: InputMaybe<Block_Height>;
};


export type QueryAssimilatorArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryAssimilatorsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Assimilator_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Assimilator_Filter>;
};


export type QueryDfxdayDataArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryDfxdayDatasArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<DfxDayData_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<DfxDayData_Filter>;
};


export type QueryDfxfactoryV3Args = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryDfxfactoryV3SArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<DfxFactoryV3_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<DfxFactoryV3_Filter>;
};


export type QueryOracleArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryOraclesArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Oracle_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Oracle_Filter>;
};


export type QueryPairArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryPairDayDataArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryPairDayDatasArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<PairDayData_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<PairDayData_Filter>;
};


export type QueryPairHourDataArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryPairHourDatasArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<PairHourData_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<PairHourData_Filter>;
};


export type QueryPairsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Pair_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Pair_Filter>;
};


export type QueryPoolParticipantArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryPoolParticipantsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<PoolParticipant_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<PoolParticipant_Filter>;
};


export type QueryTokenArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryTokenDayDataArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryTokenDayDatasArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<TokenDayData_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<TokenDayData_Filter>;
};


export type QueryTokensArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Token_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Token_Filter>;
};


export type QueryTradeArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryTradesArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Trade_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Trade_Filter>;
};


export type QueryTransferArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryTransfersArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Transfer_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Transfer_Filter>;
};

export type Subscription = {
  __typename?: 'Subscription';
  /** Access to subgraph metadata */
  _meta?: Maybe<_Meta_>;
  assimilator?: Maybe<Assimilator>;
  assimilators: Array<Assimilator>;
  dfxdayData?: Maybe<DfxDayData>;
  dfxdayDatas: Array<DfxDayData>;
  dfxfactoryV3?: Maybe<DfxFactoryV3>;
  dfxfactoryV3S: Array<DfxFactoryV3>;
  oracle?: Maybe<Oracle>;
  oracles: Array<Oracle>;
  pair?: Maybe<Pair>;
  pairDayData?: Maybe<PairDayData>;
  pairDayDatas: Array<PairDayData>;
  pairHourData?: Maybe<PairHourData>;
  pairHourDatas: Array<PairHourData>;
  pairs: Array<Pair>;
  poolParticipant?: Maybe<PoolParticipant>;
  poolParticipants: Array<PoolParticipant>;
  token?: Maybe<Token>;
  tokenDayData?: Maybe<TokenDayData>;
  tokenDayDatas: Array<TokenDayData>;
  tokens: Array<Token>;
  trade?: Maybe<Trade>;
  trades: Array<Trade>;
  transfer?: Maybe<Transfer>;
  transfers: Array<Transfer>;
};


export type Subscription_MetaArgs = {
  block?: InputMaybe<Block_Height>;
};


export type SubscriptionAssimilatorArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionAssimilatorsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Assimilator_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Assimilator_Filter>;
};


export type SubscriptionDfxdayDataArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionDfxdayDatasArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<DfxDayData_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<DfxDayData_Filter>;
};


export type SubscriptionDfxfactoryV3Args = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionDfxfactoryV3SArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<DfxFactoryV3_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<DfxFactoryV3_Filter>;
};


export type SubscriptionOracleArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionOraclesArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Oracle_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Oracle_Filter>;
};


export type SubscriptionPairArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionPairDayDataArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionPairDayDatasArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<PairDayData_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<PairDayData_Filter>;
};


export type SubscriptionPairHourDataArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionPairHourDatasArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<PairHourData_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<PairHourData_Filter>;
};


export type SubscriptionPairsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Pair_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Pair_Filter>;
};


export type SubscriptionPoolParticipantArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionPoolParticipantsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<PoolParticipant_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<PoolParticipant_Filter>;
};


export type SubscriptionTokenArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionTokenDayDataArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionTokenDayDatasArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<TokenDayData_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<TokenDayData_Filter>;
};


export type SubscriptionTokensArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Token_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Token_Filter>;
};


export type SubscriptionTradeArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionTradesArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Trade_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Trade_Filter>;
};


export type SubscriptionTransferArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID']['input'];
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionTransfersArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Transfer_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Transfer_Filter>;
};

export type Token = {
  __typename?: 'Token';
  decimals: Scalars['BigInt']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  oracle: Oracle;
  priceUSD: Scalars['BigDecimal']['output'];
  symbol: Scalars['String']['output'];
  tokenDayData: Array<TokenDayData>;
};


export type TokenTokenDayDataArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<TokenDayData_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TokenDayData_Filter>;
};

export type TokenDayData = {
  __typename?: 'TokenDayData';
  dailyVolumeToken: Scalars['BigDecimal']['output'];
  dailyVolumeUSD: Scalars['BigDecimal']['output'];
  date: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  priceUSD: Scalars['BigDecimal']['output'];
  token: Token;
};

export type TokenDayData_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<TokenDayData_Filter>>>;
  dailyVolumeToken?: InputMaybe<Scalars['BigDecimal']['input']>;
  dailyVolumeToken_gt?: InputMaybe<Scalars['BigDecimal']['input']>;
  dailyVolumeToken_gte?: InputMaybe<Scalars['BigDecimal']['input']>;
  dailyVolumeToken_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  dailyVolumeToken_lt?: InputMaybe<Scalars['BigDecimal']['input']>;
  dailyVolumeToken_lte?: InputMaybe<Scalars['BigDecimal']['input']>;
  dailyVolumeToken_not?: InputMaybe<Scalars['BigDecimal']['input']>;
  dailyVolumeToken_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  dailyVolumeUSD?: InputMaybe<Scalars['BigDecimal']['input']>;
  dailyVolumeUSD_gt?: InputMaybe<Scalars['BigDecimal']['input']>;
  dailyVolumeUSD_gte?: InputMaybe<Scalars['BigDecimal']['input']>;
  dailyVolumeUSD_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  dailyVolumeUSD_lt?: InputMaybe<Scalars['BigDecimal']['input']>;
  dailyVolumeUSD_lte?: InputMaybe<Scalars['BigDecimal']['input']>;
  dailyVolumeUSD_not?: InputMaybe<Scalars['BigDecimal']['input']>;
  dailyVolumeUSD_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  date?: InputMaybe<Scalars['Int']['input']>;
  date_gt?: InputMaybe<Scalars['Int']['input']>;
  date_gte?: InputMaybe<Scalars['Int']['input']>;
  date_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  date_lt?: InputMaybe<Scalars['Int']['input']>;
  date_lte?: InputMaybe<Scalars['Int']['input']>;
  date_not?: InputMaybe<Scalars['Int']['input']>;
  date_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  or?: InputMaybe<Array<InputMaybe<TokenDayData_Filter>>>;
  priceUSD?: InputMaybe<Scalars['BigDecimal']['input']>;
  priceUSD_gt?: InputMaybe<Scalars['BigDecimal']['input']>;
  priceUSD_gte?: InputMaybe<Scalars['BigDecimal']['input']>;
  priceUSD_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  priceUSD_lt?: InputMaybe<Scalars['BigDecimal']['input']>;
  priceUSD_lte?: InputMaybe<Scalars['BigDecimal']['input']>;
  priceUSD_not?: InputMaybe<Scalars['BigDecimal']['input']>;
  priceUSD_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  token?: InputMaybe<Scalars['String']['input']>;
  token_?: InputMaybe<Token_Filter>;
  token_contains?: InputMaybe<Scalars['String']['input']>;
  token_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  token_ends_with?: InputMaybe<Scalars['String']['input']>;
  token_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  token_gt?: InputMaybe<Scalars['String']['input']>;
  token_gte?: InputMaybe<Scalars['String']['input']>;
  token_in?: InputMaybe<Array<Scalars['String']['input']>>;
  token_lt?: InputMaybe<Scalars['String']['input']>;
  token_lte?: InputMaybe<Scalars['String']['input']>;
  token_not?: InputMaybe<Scalars['String']['input']>;
  token_not_contains?: InputMaybe<Scalars['String']['input']>;
  token_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  token_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  token_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  token_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  token_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  token_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  token_starts_with?: InputMaybe<Scalars['String']['input']>;
  token_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
};

export enum TokenDayData_OrderBy {
  DailyVolumeToken = 'dailyVolumeToken',
  DailyVolumeUsd = 'dailyVolumeUSD',
  Date = 'date',
  Id = 'id',
  PriceUsd = 'priceUSD',
  Token = 'token',
  TokenDecimals = 'token__decimals',
  TokenId = 'token__id',
  TokenName = 'token__name',
  TokenPriceUsd = 'token__priceUSD',
  TokenSymbol = 'token__symbol'
}

export type Token_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Token_Filter>>>;
  decimals?: InputMaybe<Scalars['BigInt']['input']>;
  decimals_gt?: InputMaybe<Scalars['BigInt']['input']>;
  decimals_gte?: InputMaybe<Scalars['BigInt']['input']>;
  decimals_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  decimals_lt?: InputMaybe<Scalars['BigInt']['input']>;
  decimals_lte?: InputMaybe<Scalars['BigInt']['input']>;
  decimals_not?: InputMaybe<Scalars['BigInt']['input']>;
  decimals_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  name_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_gt?: InputMaybe<Scalars['String']['input']>;
  name_gte?: InputMaybe<Scalars['String']['input']>;
  name_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_lt?: InputMaybe<Scalars['String']['input']>;
  name_lte?: InputMaybe<Scalars['String']['input']>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  or?: InputMaybe<Array<InputMaybe<Token_Filter>>>;
  oracle?: InputMaybe<Scalars['String']['input']>;
  oracle_?: InputMaybe<Oracle_Filter>;
  oracle_contains?: InputMaybe<Scalars['String']['input']>;
  oracle_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  oracle_ends_with?: InputMaybe<Scalars['String']['input']>;
  oracle_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  oracle_gt?: InputMaybe<Scalars['String']['input']>;
  oracle_gte?: InputMaybe<Scalars['String']['input']>;
  oracle_in?: InputMaybe<Array<Scalars['String']['input']>>;
  oracle_lt?: InputMaybe<Scalars['String']['input']>;
  oracle_lte?: InputMaybe<Scalars['String']['input']>;
  oracle_not?: InputMaybe<Scalars['String']['input']>;
  oracle_not_contains?: InputMaybe<Scalars['String']['input']>;
  oracle_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  oracle_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  oracle_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  oracle_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  oracle_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  oracle_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  oracle_starts_with?: InputMaybe<Scalars['String']['input']>;
  oracle_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  priceUSD?: InputMaybe<Scalars['BigDecimal']['input']>;
  priceUSD_gt?: InputMaybe<Scalars['BigDecimal']['input']>;
  priceUSD_gte?: InputMaybe<Scalars['BigDecimal']['input']>;
  priceUSD_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  priceUSD_lt?: InputMaybe<Scalars['BigDecimal']['input']>;
  priceUSD_lte?: InputMaybe<Scalars['BigDecimal']['input']>;
  priceUSD_not?: InputMaybe<Scalars['BigDecimal']['input']>;
  priceUSD_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  symbol?: InputMaybe<Scalars['String']['input']>;
  symbol_contains?: InputMaybe<Scalars['String']['input']>;
  symbol_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  symbol_ends_with?: InputMaybe<Scalars['String']['input']>;
  symbol_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  symbol_gt?: InputMaybe<Scalars['String']['input']>;
  symbol_gte?: InputMaybe<Scalars['String']['input']>;
  symbol_in?: InputMaybe<Array<Scalars['String']['input']>>;
  symbol_lt?: InputMaybe<Scalars['String']['input']>;
  symbol_lte?: InputMaybe<Scalars['String']['input']>;
  symbol_not?: InputMaybe<Scalars['String']['input']>;
  symbol_not_contains?: InputMaybe<Scalars['String']['input']>;
  symbol_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  symbol_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  symbol_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  symbol_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  symbol_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  symbol_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  symbol_starts_with?: InputMaybe<Scalars['String']['input']>;
  symbol_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  tokenDayData_?: InputMaybe<TokenDayData_Filter>;
};

export enum Token_OrderBy {
  Decimals = 'decimals',
  Id = 'id',
  Name = 'name',
  Oracle = 'oracle',
  OracleDecimals = 'oracle__decimals',
  OracleId = 'oracle__id',
  OracleToken = 'oracle__token',
  PriceUsd = 'priceUSD',
  Symbol = 'symbol',
  TokenDayData = 'tokenDayData'
}

export type Trade = {
  __typename?: 'Trade';
  fee: Scalars['BigInt']['output'];
  id: Scalars['ID']['output'];
  origin: Scalars['Bytes']['output'];
  originAmount: Scalars['BigInt']['output'];
  pair: Pair;
  target: Scalars['Bytes']['output'];
  targetAmount: Scalars['BigInt']['output'];
  timestamp: Scalars['BigInt']['output'];
  trader: Scalars['Bytes']['output'];
};

export type Trade_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Trade_Filter>>>;
  fee?: InputMaybe<Scalars['BigInt']['input']>;
  fee_gt?: InputMaybe<Scalars['BigInt']['input']>;
  fee_gte?: InputMaybe<Scalars['BigInt']['input']>;
  fee_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  fee_lt?: InputMaybe<Scalars['BigInt']['input']>;
  fee_lte?: InputMaybe<Scalars['BigInt']['input']>;
  fee_not?: InputMaybe<Scalars['BigInt']['input']>;
  fee_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  or?: InputMaybe<Array<InputMaybe<Trade_Filter>>>;
  origin?: InputMaybe<Scalars['Bytes']['input']>;
  originAmount?: InputMaybe<Scalars['BigInt']['input']>;
  originAmount_gt?: InputMaybe<Scalars['BigInt']['input']>;
  originAmount_gte?: InputMaybe<Scalars['BigInt']['input']>;
  originAmount_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  originAmount_lt?: InputMaybe<Scalars['BigInt']['input']>;
  originAmount_lte?: InputMaybe<Scalars['BigInt']['input']>;
  originAmount_not?: InputMaybe<Scalars['BigInt']['input']>;
  originAmount_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  origin_contains?: InputMaybe<Scalars['Bytes']['input']>;
  origin_gt?: InputMaybe<Scalars['Bytes']['input']>;
  origin_gte?: InputMaybe<Scalars['Bytes']['input']>;
  origin_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  origin_lt?: InputMaybe<Scalars['Bytes']['input']>;
  origin_lte?: InputMaybe<Scalars['Bytes']['input']>;
  origin_not?: InputMaybe<Scalars['Bytes']['input']>;
  origin_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  origin_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  pair?: InputMaybe<Scalars['String']['input']>;
  pair_?: InputMaybe<Pair_Filter>;
  pair_contains?: InputMaybe<Scalars['String']['input']>;
  pair_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  pair_ends_with?: InputMaybe<Scalars['String']['input']>;
  pair_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  pair_gt?: InputMaybe<Scalars['String']['input']>;
  pair_gte?: InputMaybe<Scalars['String']['input']>;
  pair_in?: InputMaybe<Array<Scalars['String']['input']>>;
  pair_lt?: InputMaybe<Scalars['String']['input']>;
  pair_lte?: InputMaybe<Scalars['String']['input']>;
  pair_not?: InputMaybe<Scalars['String']['input']>;
  pair_not_contains?: InputMaybe<Scalars['String']['input']>;
  pair_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  pair_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  pair_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  pair_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  pair_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  pair_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  pair_starts_with?: InputMaybe<Scalars['String']['input']>;
  pair_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  target?: InputMaybe<Scalars['Bytes']['input']>;
  targetAmount?: InputMaybe<Scalars['BigInt']['input']>;
  targetAmount_gt?: InputMaybe<Scalars['BigInt']['input']>;
  targetAmount_gte?: InputMaybe<Scalars['BigInt']['input']>;
  targetAmount_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  targetAmount_lt?: InputMaybe<Scalars['BigInt']['input']>;
  targetAmount_lte?: InputMaybe<Scalars['BigInt']['input']>;
  targetAmount_not?: InputMaybe<Scalars['BigInt']['input']>;
  targetAmount_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  target_contains?: InputMaybe<Scalars['Bytes']['input']>;
  target_gt?: InputMaybe<Scalars['Bytes']['input']>;
  target_gte?: InputMaybe<Scalars['Bytes']['input']>;
  target_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  target_lt?: InputMaybe<Scalars['Bytes']['input']>;
  target_lte?: InputMaybe<Scalars['Bytes']['input']>;
  target_not?: InputMaybe<Scalars['Bytes']['input']>;
  target_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  target_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  timestamp?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  timestamp_lt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_lte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_not?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  trader?: InputMaybe<Scalars['Bytes']['input']>;
  trader_contains?: InputMaybe<Scalars['Bytes']['input']>;
  trader_gt?: InputMaybe<Scalars['Bytes']['input']>;
  trader_gte?: InputMaybe<Scalars['Bytes']['input']>;
  trader_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  trader_lt?: InputMaybe<Scalars['Bytes']['input']>;
  trader_lte?: InputMaybe<Scalars['Bytes']['input']>;
  trader_not?: InputMaybe<Scalars['Bytes']['input']>;
  trader_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  trader_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
};

export enum Trade_OrderBy {
  Fee = 'fee',
  Id = 'id',
  Origin = 'origin',
  OriginAmount = 'originAmount',
  Pair = 'pair',
  PairAlpha = 'pair__alpha',
  PairBeta = 'pair__beta',
  PairDecimals = 'pair__decimals',
  PairDelta = 'pair__delta',
  PairDfxApproved = 'pair__dfxApproved',
  PairEpsilon = 'pair__epsilon',
  PairId = 'pair__id',
  PairLambda = 'pair__lambda',
  PairName = 'pair__name',
  PairParticipantCount = 'pair__participantCount',
  PairProtocolFee = 'pair__protocolFee',
  PairReserve0 = 'pair__reserve0',
  PairReserve0Usd = 'pair__reserve0USD',
  PairReserve1 = 'pair__reserve1',
  PairReserve1Usd = 'pair__reserve1USD',
  PairReserveNative = 'pair__reserveNative',
  PairReserveUsd = 'pair__reserveUSD',
  PairSwapRateFrom0To1 = 'pair__swapRateFrom0To1',
  PairSwapRateFrom0To1Usd = 'pair__swapRateFrom0To1USD',
  PairSwapRateFrom1To0 = 'pair__swapRateFrom1To0',
  PairSwapRateFrom1To0Usd = 'pair__swapRateFrom1To0USD',
  PairSymbol = 'pair__symbol',
  PairTotalFeeUsd = 'pair__totalFeeUSD',
  PairTotalLpToken = 'pair__totalLPToken',
  PairTxnsCount = 'pair__txnsCount',
  PairVolumeToken0 = 'pair__volumeToken0',
  PairVolumeToken0Usd = 'pair__volumeToken0USD',
  PairVolumeToken1 = 'pair__volumeToken1',
  PairVolumeToken1Usd = 'pair__volumeToken1USD',
  Target = 'target',
  TargetAmount = 'targetAmount',
  Timestamp = 'timestamp',
  Trader = 'trader'
}

export type Transfer = {
  __typename?: 'Transfer';
  from: Scalars['Bytes']['output'];
  id: Scalars['ID']['output'];
  pair: Pair;
  timestamp: Scalars['BigInt']['output'];
  to: Scalars['Bytes']['output'];
  token0Amount: Scalars['BigDecimal']['output'];
  token1Amount: Scalars['BigDecimal']['output'];
  tokenLPTAmount: Scalars['BigInt']['output'];
  type: Scalars['String']['output'];
};

export type Transfer_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Transfer_Filter>>>;
  from?: InputMaybe<Scalars['Bytes']['input']>;
  from_contains?: InputMaybe<Scalars['Bytes']['input']>;
  from_gt?: InputMaybe<Scalars['Bytes']['input']>;
  from_gte?: InputMaybe<Scalars['Bytes']['input']>;
  from_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  from_lt?: InputMaybe<Scalars['Bytes']['input']>;
  from_lte?: InputMaybe<Scalars['Bytes']['input']>;
  from_not?: InputMaybe<Scalars['Bytes']['input']>;
  from_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  from_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  or?: InputMaybe<Array<InputMaybe<Transfer_Filter>>>;
  pair?: InputMaybe<Scalars['String']['input']>;
  pair_?: InputMaybe<Pair_Filter>;
  pair_contains?: InputMaybe<Scalars['String']['input']>;
  pair_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  pair_ends_with?: InputMaybe<Scalars['String']['input']>;
  pair_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  pair_gt?: InputMaybe<Scalars['String']['input']>;
  pair_gte?: InputMaybe<Scalars['String']['input']>;
  pair_in?: InputMaybe<Array<Scalars['String']['input']>>;
  pair_lt?: InputMaybe<Scalars['String']['input']>;
  pair_lte?: InputMaybe<Scalars['String']['input']>;
  pair_not?: InputMaybe<Scalars['String']['input']>;
  pair_not_contains?: InputMaybe<Scalars['String']['input']>;
  pair_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  pair_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  pair_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  pair_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  pair_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  pair_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  pair_starts_with?: InputMaybe<Scalars['String']['input']>;
  pair_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  timestamp?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  timestamp_lt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_lte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_not?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  to?: InputMaybe<Scalars['Bytes']['input']>;
  to_contains?: InputMaybe<Scalars['Bytes']['input']>;
  to_gt?: InputMaybe<Scalars['Bytes']['input']>;
  to_gte?: InputMaybe<Scalars['Bytes']['input']>;
  to_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  to_lt?: InputMaybe<Scalars['Bytes']['input']>;
  to_lte?: InputMaybe<Scalars['Bytes']['input']>;
  to_not?: InputMaybe<Scalars['Bytes']['input']>;
  to_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  to_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  token0Amount?: InputMaybe<Scalars['BigDecimal']['input']>;
  token0Amount_gt?: InputMaybe<Scalars['BigDecimal']['input']>;
  token0Amount_gte?: InputMaybe<Scalars['BigDecimal']['input']>;
  token0Amount_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  token0Amount_lt?: InputMaybe<Scalars['BigDecimal']['input']>;
  token0Amount_lte?: InputMaybe<Scalars['BigDecimal']['input']>;
  token0Amount_not?: InputMaybe<Scalars['BigDecimal']['input']>;
  token0Amount_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  token1Amount?: InputMaybe<Scalars['BigDecimal']['input']>;
  token1Amount_gt?: InputMaybe<Scalars['BigDecimal']['input']>;
  token1Amount_gte?: InputMaybe<Scalars['BigDecimal']['input']>;
  token1Amount_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  token1Amount_lt?: InputMaybe<Scalars['BigDecimal']['input']>;
  token1Amount_lte?: InputMaybe<Scalars['BigDecimal']['input']>;
  token1Amount_not?: InputMaybe<Scalars['BigDecimal']['input']>;
  token1Amount_not_in?: InputMaybe<Array<Scalars['BigDecimal']['input']>>;
  tokenLPTAmount?: InputMaybe<Scalars['BigInt']['input']>;
  tokenLPTAmount_gt?: InputMaybe<Scalars['BigInt']['input']>;
  tokenLPTAmount_gte?: InputMaybe<Scalars['BigInt']['input']>;
  tokenLPTAmount_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  tokenLPTAmount_lt?: InputMaybe<Scalars['BigInt']['input']>;
  tokenLPTAmount_lte?: InputMaybe<Scalars['BigInt']['input']>;
  tokenLPTAmount_not?: InputMaybe<Scalars['BigInt']['input']>;
  tokenLPTAmount_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  type?: InputMaybe<Scalars['String']['input']>;
  type_contains?: InputMaybe<Scalars['String']['input']>;
  type_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  type_ends_with?: InputMaybe<Scalars['String']['input']>;
  type_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  type_gt?: InputMaybe<Scalars['String']['input']>;
  type_gte?: InputMaybe<Scalars['String']['input']>;
  type_in?: InputMaybe<Array<Scalars['String']['input']>>;
  type_lt?: InputMaybe<Scalars['String']['input']>;
  type_lte?: InputMaybe<Scalars['String']['input']>;
  type_not?: InputMaybe<Scalars['String']['input']>;
  type_not_contains?: InputMaybe<Scalars['String']['input']>;
  type_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  type_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  type_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  type_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  type_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  type_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  type_starts_with?: InputMaybe<Scalars['String']['input']>;
  type_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
};

export enum Transfer_OrderBy {
  From = 'from',
  Id = 'id',
  Pair = 'pair',
  PairAlpha = 'pair__alpha',
  PairBeta = 'pair__beta',
  PairDecimals = 'pair__decimals',
  PairDelta = 'pair__delta',
  PairDfxApproved = 'pair__dfxApproved',
  PairEpsilon = 'pair__epsilon',
  PairId = 'pair__id',
  PairLambda = 'pair__lambda',
  PairName = 'pair__name',
  PairParticipantCount = 'pair__participantCount',
  PairProtocolFee = 'pair__protocolFee',
  PairReserve0 = 'pair__reserve0',
  PairReserve0Usd = 'pair__reserve0USD',
  PairReserve1 = 'pair__reserve1',
  PairReserve1Usd = 'pair__reserve1USD',
  PairReserveNative = 'pair__reserveNative',
  PairReserveUsd = 'pair__reserveUSD',
  PairSwapRateFrom0To1 = 'pair__swapRateFrom0To1',
  PairSwapRateFrom0To1Usd = 'pair__swapRateFrom0To1USD',
  PairSwapRateFrom1To0 = 'pair__swapRateFrom1To0',
  PairSwapRateFrom1To0Usd = 'pair__swapRateFrom1To0USD',
  PairSymbol = 'pair__symbol',
  PairTotalFeeUsd = 'pair__totalFeeUSD',
  PairTotalLpToken = 'pair__totalLPToken',
  PairTxnsCount = 'pair__txnsCount',
  PairVolumeToken0 = 'pair__volumeToken0',
  PairVolumeToken0Usd = 'pair__volumeToken0USD',
  PairVolumeToken1 = 'pair__volumeToken1',
  PairVolumeToken1Usd = 'pair__volumeToken1USD',
  Timestamp = 'timestamp',
  To = 'to',
  Token0Amount = 'token0Amount',
  Token1Amount = 'token1Amount',
  TokenLptAmount = 'tokenLPTAmount',
  Type = 'type'
}

export type _Block_ = {
  __typename?: '_Block_';
  /** The hash of the block */
  hash?: Maybe<Scalars['Bytes']['output']>;
  /** The block number */
  number: Scalars['Int']['output'];
  /** The hash of the parent block */
  parentHash?: Maybe<Scalars['Bytes']['output']>;
  /** Integer representation of the timestamp stored in blocks for the chain */
  timestamp?: Maybe<Scalars['Int']['output']>;
};

/** The type for the top-level _meta field */
export type _Meta_ = {
  __typename?: '_Meta_';
  /**
   * Information about a specific subgraph block. The hash of the block
   * will be null if the _meta field has a block constraint that asks for
   * a block number. It will be filled if the _meta field has no block constraint
   * and therefore asks for the latest  block
   *
   */
  block: _Block_;
  /** The deployment ID */
  deployment: Scalars['String']['output'];
  /** If `true`, the subgraph encountered indexing errors at some past block */
  hasIndexingErrors: Scalars['Boolean']['output'];
};

export enum _SubgraphErrorPolicy_ {
  /** Data will be returned even if the subgraph has indexing errors */
  Allow = 'allow',
  /** If the subgraph has indexing errors, data will be omitted. The default. */
  Deny = 'deny'
}

export type GetPoolsDataQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPoolsDataQuery = { __typename?: 'Query', pairs: Array<{ __typename?: 'Pair', id: string, decimals: any, symbol: string, name: string, protocolFee: any, epsilon: any, reserveUSD: any, reserve0: any, reserve1: any, totalLPToken: any, token0: { __typename?: 'Token', id: string, decimals: any, symbol: string, name: string, oracle: { __typename?: 'Oracle', id: string, token: any, decimals: any } }, token1: { __typename?: 'Token', id: string, decimals: any, symbol: string, name: string, oracle: { __typename?: 'Oracle', id: string, token: any, decimals: any } } }> };

export type GetChainQueryVariables = Exact<{
  dateGte: Scalars['Int']['input'];
  dateLt: Scalars['Int']['input'];
}>;


export type GetChainQuery = { __typename?: 'Query', pairDayDatas: Array<{ __typename?: 'PairDayData', id: string, date: number, volumeUSD: any }> };


export const GetPoolsDataDocument = gql`
    query GetPoolsData {
  pairs {
    id
    decimals
    symbol
    name
    protocolFee
    epsilon
    token0 {
      id
      decimals
      symbol
      name
      oracle {
        id
        token
        decimals
      }
    }
    token1 {
      id
      decimals
      symbol
      name
      oracle {
        id
        token
        decimals
      }
    }
    reserveUSD
    reserve0
    reserve1
    totalLPToken
  }
}
    `;

/**
 * __useGetPoolsDataQuery__
 *
 * To run a query within a React component, call `useGetPoolsDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPoolsDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPoolsDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPoolsDataQuery(baseOptions?: Apollo.QueryHookOptions<GetPoolsDataQuery, GetPoolsDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPoolsDataQuery, GetPoolsDataQueryVariables>(GetPoolsDataDocument, options);
      }
export function useGetPoolsDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPoolsDataQuery, GetPoolsDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPoolsDataQuery, GetPoolsDataQueryVariables>(GetPoolsDataDocument, options);
        }
export function useGetPoolsDataSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetPoolsDataQuery, GetPoolsDataQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetPoolsDataQuery, GetPoolsDataQueryVariables>(GetPoolsDataDocument, options);
        }
export type GetPoolsDataQueryHookResult = ReturnType<typeof useGetPoolsDataQuery>;
export type GetPoolsDataLazyQueryHookResult = ReturnType<typeof useGetPoolsDataLazyQuery>;
export type GetPoolsDataSuspenseQueryHookResult = ReturnType<typeof useGetPoolsDataSuspenseQuery>;
export type GetPoolsDataQueryResult = Apollo.QueryResult<GetPoolsDataQuery, GetPoolsDataQueryVariables>;
export const GetChainDocument = gql`
    query GetChain($dateGte: Int!, $dateLt: Int!) {
  pairDayDatas(
    first: 1000
    where: {date_gte: $dateGte, date_lt: $dateLt}
    orderBy: date
    orderDirection: desc
  ) {
    id
    date
    volumeUSD
  }
}
    `;

/**
 * __useGetChainQuery__
 *
 * To run a query within a React component, call `useGetChainQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChainQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChainQuery({
 *   variables: {
 *      dateGte: // value for 'dateGte'
 *      dateLt: // value for 'dateLt'
 *   },
 * });
 */
export function useGetChainQuery(baseOptions: Apollo.QueryHookOptions<GetChainQuery, GetChainQueryVariables> & ({ variables: GetChainQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetChainQuery, GetChainQueryVariables>(GetChainDocument, options);
      }
export function useGetChainLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetChainQuery, GetChainQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetChainQuery, GetChainQueryVariables>(GetChainDocument, options);
        }
export function useGetChainSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetChainQuery, GetChainQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetChainQuery, GetChainQueryVariables>(GetChainDocument, options);
        }
export type GetChainQueryHookResult = ReturnType<typeof useGetChainQuery>;
export type GetChainLazyQueryHookResult = ReturnType<typeof useGetChainLazyQuery>;
export type GetChainSuspenseQueryHookResult = ReturnType<typeof useGetChainSuspenseQuery>;
export type GetChainQueryResult = Apollo.QueryResult<GetChainQuery, GetChainQueryVariables>;
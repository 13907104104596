import { ChangeEvent, useEffect, useState } from "react";
import { parseUnits } from "viem";

import { TokenIcon } from "@/components/token-icon";
import { cn, formatDecimalString } from "@/lib/utils";
import { toW3Number, W3Number } from "@/lib/w3Num";
import { Skeleton } from "./ui/skeleton";

interface TokenInputProps {
  token: string;
  tokenDec: number;
  balance: W3Number | null;
  tokenAmount: W3Number | null;
  setTokenAmount: (arg: W3Number) => void;
  controlled: boolean;
}
export const SwapTokenInput = ({
  token,
  tokenDec,
  balance,
  tokenAmount,
  setTokenAmount,
  controlled,
}: TokenInputProps) => {
  const [isError, setIsError] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const bal_ = balance !== null ? balance.big : 0n;

  useEffect(() => {
    if (!tokenAmount) {
      setInputValue("");
    }
  }, [tokenAmount, tokenDec]);

  useEffect(() => {
    const minAmount = parseUnits("0.01", tokenDec);

    if (tokenAmount) {
      if (tokenAmount.big > bal_) {
        setIsError(true);
      } else if (tokenAmount.big === 0n) {
        setIsError(false);
      } else if (tokenAmount.big < minAmount) {
        setIsError(true);
      } else {
        setIsError(false);
      }
    } else {
      setIsError(false);
    }
  }, [bal_, tokenAmount, tokenDec]);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    if (value === "") {
      setInputValue("");
      setTokenAmount(toW3Number(0n, 0, 0));
      return;
    }

    // regex matches: ".35", "0.35", "123", "12.3", "123."
    const regex = /^(\d+\.?\d*|\.\d*)$/;
    if (regex.test(value)) {
      setInputValue(value);

      if (value === ".") {
        setTokenAmount(toW3Number(0n, 0, 0));
        return;
      }

      const bigIntValue = parseUnits(value, tokenDec);
      setTokenAmount(toW3Number(bigIntValue, tokenDec, tokenDec));
    } else {
      console.error("Invalid input:", value);
    }
  };

  const handleBalanceClick = () => {
    setTokenAmount(toW3Number(bal_, tokenDec, tokenDec));
    setInputValue(formatDecimalString(bal_, tokenDec, 6));
  };

  const displayValue =
    !controlled && tokenAmount?.dsp ? tokenAmount.dsp : inputValue;

  return (
    <div
      className={cn(
        "flex flex-col w-full justify-between",
        "py-2 pl-4 pr-8",
        "border rounded-md",
        "dark:border-gray-900 dark:bg-gray-800",
        "hover:cursor-pointer"
      )}
    >
      <div className="flex w-full">
        {/* Icon & Label */}
        <div className="flex items-center gap-3">
          {token ? (
            <>
              <TokenIcon name={token} classNames="w-6 h-6" />
              <span className="font-semibold sm:text-base text-foreground">
                {token}
              </span>
            </>
          ) : (
            <>
              <Skeleton className="w-6 h-6 rounded-full" />
            </>
          )}
        </div>
        {/* Value input */}
        <div className="flex-1">
          <input
            type="text"
            value={displayValue}
            onChange={handleInputChange}
            onFocus={(e) => e.target.select()}
            placeholder="0"
            className={cn(
              "w-full bg-transparent h-10 text-right text-md sm:text-md text-foreground focus:outline-none",
              isError
                ? "border-red-500 placeholder-red-700"
                : "placeholder-foreground"
            )}
          />
        </div>
      </div>

      {/* Error */}
      {isError && controlled && (
        <p className="text-red-500 text-xs">
          {tokenAmount && tokenAmount.big > bal_
            ? "Insufficient balance"
            : tokenAmount && tokenAmount.big > 0n
            ? `Minimum amount is 0.01 ${token}`
            : ""}
        </p>
      )}

      {/* Balance */}
      <div
        className="text-left"
        onClick={handleBalanceClick}
        onKeyDown={handleBalanceClick}
        role="button"
        tabIndex={0}
      >
        <span
          className={`text-md sm:text-sm ${
            isError && controlled ? "text-red-900" : "text-primary"
          }`}
        >
          <span>Balance: {formatDecimalString(bal_, tokenDec, 2)}</span>
        </span>
      </div>
    </div>
  );
};

import { useOraclePrice } from "@/hooks/useOraclePrice";
import { Token } from "@/hooks/usePools";
import { useMemo } from "react";

interface Props {
  baseToken: Token;
  quoteToken: Token;
}
export const PoolDerivatives: React.FC<Props> = ({ baseToken, quoteToken }) => {
  const { oraclePrice } = useOraclePrice(baseToken, quoteToken);

  const basePct = useMemo(() => {
    if (oraclePrice && baseToken.amount && quoteToken.amount) {
      const baseVal = baseToken.amount * oraclePrice;
      const quoteVal = quoteToken.amount * 1;
      return baseVal / (baseVal + quoteVal);
    }
    return null;
  }, [baseToken.amount, quoteToken.amount, oraclePrice]);

  const quotePct = useMemo(() => {
    if (oraclePrice && baseToken.amount && quoteToken.amount) {
      const baseVal = baseToken.amount * oraclePrice;
      const quoteVal = quoteToken.amount * 1;
      return quoteVal / (baseVal + quoteVal);
    }
    return null;
  }, [baseToken.amount, quoteToken.amount, oraclePrice]);

  return (
    <div className="bg-gray-50 dark:bg-gray-800/50 rounded-lg p-4 h-24">
      <div className="flex items-center justify-between mb-3">
        <span className="text-gray-400 text-sm font-medium">Composition</span>
      </div>
      <div className="flex items-center gap-3">
        <div className="flex gap-x-1">
          <div className="flex gap-x-1 items-center">
            {basePct ? (basePct * 100).toPrecision(4) : "--"}%
            <img
              alt={`${baseToken.symbol} icon`}
              src={baseToken.icon}
              width={16}
            />
          </div>
          <span className="text-gray-300/50">/</span>
          <div className="flex gap-x-1 items-center">
            {quotePct ? (quotePct * 100).toPrecision(4) : "--"}%
            <img
              alt={`${quoteToken.symbol} icon`}
              src={quoteToken.icon}
              width={16}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

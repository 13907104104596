import { cn } from "@/lib/utils";

interface Props {
  name: string;
  classNames?: string;
}

export const TokenIcon: React.FC<Props> = ({ name, classNames }) => {
  return (
    <div className="flex flex-col items-center justify-center">
      <img
        src={`/assets/${name}.svg`}
        alt=""
        className={cn("w-8 h-8", classNames)}
      />
    </div>
  );
};

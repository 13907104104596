import { useMemo } from "react";
import { usePools } from "./usePools";

export const useUsdcToken = () => {
  const { tokens, tokenAddrs } = usePools();

  const usdc = useMemo(() => {
    if (tokenAddrs) {
      const usdcAddr = tokenAddrs.find((t) => tokens[t].symbol === "USDC");
      if (usdcAddr) {
        return tokens[usdcAddr];
      }
    }
    return null;
  }, [tokenAddrs, tokens]);

  return {
    usdc,
  };
};

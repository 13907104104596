import { formatUnits } from "viem";

interface Props {
  lptAmount: bigint | null;
  lptValueUsd: number | null;
  lptPercentToReceive: number | null;
}

export const AddLptStats: React.FC<Props> = ({
  lptAmount,
  lptValueUsd,
  lptPercentToReceive,
}) => {
  return (
    <div className="flex flex-col text-sm">
      <div className="flex w-full">
        <div className="flex">Est. Pool Allocation</div>
        <div className="flex-grow font-semibold text-end">
          {lptAmount
            ? `${parseFloat(formatUnits(lptAmount, 18)).toFixed(2)} LPT`
            : "--"}
        </div>
      </div>

      <div className="flex w-full">
        <div className="flex">Deposit Value</div>
        <div className="flex-grow font-semibold text-end">
          {lptValueUsd ? `$${parseFloat(lptValueUsd.toFixed(2))}` : "--"}
        </div>
      </div>

      <div className="flex w-full">
        <div className="flex">Pool Share</div>
        <div className="flex-grow font-semibold text-end">
          {lptPercentToReceive
            ? `${parseFloat(lptPercentToReceive.toFixed(2))}%`
            : "--"}
        </div>
      </div>
    </div>
  );
};

export function Logo() {
  return (
    <img
      aria-label="Sedge logo"
      alt="Sedge"
      src="/assets/sedge.svg"
      className="w-12"
    />
  );
}

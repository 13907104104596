import { FC } from "react";
import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  HttpLink,
  from,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";

import { BASE_SUBGRAPH } from "@/config/config";

// Error handling link
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

interface Props {
  children: React.ReactNode;
}

export const ApolloProviderWithClient: FC<Props> = ({ children }) => {
  const sedgeLink = new HttpLink({
    uri: BASE_SUBGRAPH,
  });
  const client = new ApolloClient({
    link: from([errorLink, sedgeLink]),
    cache: new InMemoryCache(),
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

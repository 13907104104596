import { useMemo } from "react";
import { Token } from "./usePools";
import { useSwapRoute } from "./useSwapRoute";

// Each pool in route takes a percent fee
const calcTotalFee = (fees: string[] | null): number | null => {
  if (!fees || fees.length == 0) {
    return null;
  }

  const feesNum = fees.map((fee) => (fee ? Number(fee) : 0));

  if (feesNum.length === 1) {
    return feesNum[0];
  }
  if (feesNum.length === 2) {
    return feesNum[0] + feesNum[1] * (1 - feesNum[0]);
  }
  // It's always 1 or 2 pools in route b/c USDC is the common quote token
  throw new Error("All swaps should be either 1 or 2 pools in route");
};

export const calcDisplayFees = (
  inputAmount: string,
  totalFee: number | null
): string | number => {
  if (!totalFee) {
    return "--";
  }
  return parseFloat((totalFee * parseFloat(inputAmount)).toPrecision(6));
};

export const useFees = (inToken: Token, outToken: Token) => {
  const { poolRoute } = useSwapRoute(inToken, outToken);

  // Set fees
  const data = useMemo(() => {
    const fees = poolRoute.map((pool) => pool.fee);
    return {
      fees,
      totalFee: calcTotalFee(fees),
    };
  }, [poolRoute]);

  return { ...data };
};

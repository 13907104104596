import React, { useState } from "react";

import { Button } from "@/components/ui/button";
import { W3Number } from "@/lib/w3Num";
import { useLiquidityDepositOneSided } from "@/hooks/useLiquidityDeposit";
import { Pool } from "@/pages/Pools";
import { AddLptStats } from "./add-lpt-stats";
import { LiquidityTokenInput } from "./liquidity-token-input";

interface Props {
  pool: Pool;
  closeModal: () => void;
}
export const AddOneSidedField: React.FC<Props> = ({ pool }) => {
  const [tokenA, setTokenA] = useState(pool.quoteToken);
  const [amountTokenA, setAmountTokenA] = useState<W3Number | null>(null);
  const [, setAmountTokenB] = useState<W3Number | null>(null);

  const { lptAmount, lptValueUsd, lptPercentToReceive } =
    useLiquidityDepositOneSided(pool, amountTokenA, setAmountTokenB);

  return (
    <>
      <LiquidityTokenInput
        direction="deposit"
        token={tokenA}
        inputValue={amountTokenA}
        onChange={setAmountTokenA}
        tokenOptions={[pool.quoteToken, pool.baseToken]}
        setToken={setTokenA}
      />

      {/* Added LPT stats */}
      <AddLptStats
        lptAmount={lptAmount}
        lptValueUsd={lptValueUsd}
        lptPercentToReceive={lptPercentToReceive}
      />

      {/* Add Liquidity Button */}
      <Button className="w-full dark:bg-blue-600 dark:hover:bg-blue-700 text-lg py-6">
        Add liquidity
      </Button>
    </>
  );
};

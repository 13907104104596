interface Props {
  baseIcon: string;
  quoteIcon: string;
  width?: number;
}

export const CurrencyPairIcon: React.FC<Props> = ({
  baseIcon,
  quoteIcon,
  width = 28,
}) => {
  const overlap = Math.floor(width / 3);

  return (
    <div className="relative flex flex-shrink-0">
      <span className="z-10">
        <img
          src={baseIcon}
          width={width}
          height={width}
          alt="Base currency"
          className="rounded-full"
        />
      </span>
      <span>
        <img
          src={quoteIcon}
          width={width}
          height={width}
          alt="Quote currency"
          className="rounded-full"
          style={{ transform: `translateX(-${overlap}px)` }}
        />
      </span>
    </div>
  );
};

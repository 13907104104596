import { useState } from "react";
import { CogIcon, X } from "lucide-react";

import { Button } from "@/components/ui/button";
import { Dialog, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { DialogContent } from "@/components/ui/override/dialog";
import { Pool } from "@/pages/Pools";
import { CurrencyPairIcon } from "../currency-pair-icon";
import { EditTxSettingsModal } from "../tx-settings-modal";
import { RemoveField } from "./remove-field";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  pool: Pool;
}
export const RemoveLiquidityModal: React.FC<Props> = ({
  isOpen,
  onClose,
  pool,
}) => {
  const [isTxSettingsOpen, setIsTxSettingsOpen] = useState(false);

  return (
    <>
      <Dialog open={isOpen} onOpenChange={onClose}>
        <DialogContent className="dark:bg-gray-900 dark:border-gray-800 max-w-xl p-4">
          <DialogHeader className="border-b border-gray-800 pb-4">
            <div className="flex items-center justify-between">
              <DialogTitle className="text-xl font-normal">
                Remove Liquidity
              </DialogTitle>
              <div>
                <Button
                  variant="ghost"
                  onClick={() => setIsTxSettingsOpen(true)}
                >
                  <CogIcon width={18} />
                </Button>
                <Button
                  variant="ghost"
                  size="icon"
                  onClick={onClose}
                  className="hover:bg-gray-100 dark:hover:bg-gray-800"
                >
                  <X className="h-5 w-5" />
                </Button>
              </div>
            </div>
          </DialogHeader>
          <div className="space-y-6 py-4">
            {/* Pool Selector */}
            <div className="rounder bg-gray-100 dark:bg-gray-800/50 p-4">
              <div className="flex items-center justify-between">
                <span className="text-lg">Remove liquidity from</span>
                <div className="flex items-center gap-2">
                  <CurrencyPairIcon
                    baseIcon={pool.baseToken.icon}
                    quoteIcon={pool.quoteToken.icon}
                  />
                  <span className="text-xl">{pool.label}</span>
                </div>
              </div>
            </div>
            {/* Withdraw Input */}
            <RemoveField pool={pool} closeModal={onClose} />
          </div>
        </DialogContent>
      </Dialog>

      <EditTxSettingsModal
        isOpen={isTxSettingsOpen}
        onClose={() => setIsTxSettingsOpen(false)}
      />
    </>
  );
};

import { Address } from "viem";
import { ExternalLinkIcon } from "@radix-ui/react-icons";

import { capitalize, truncateHash } from "@/lib/utils";

interface Props {
  side: "base" | "quote";
  oracle: Address;
}

export const PoolOracle: React.FC<Props> = ({ side, oracle }) => {
  return (
    <div className="bg-gray-50 dark:bg-gray-800/50 rounded-lg p-4">
      <div className="flex items-center justify-between mb-3">
        <span className="text-gray-400 text-sm font-medium">
          {capitalize(side)} Oracle
        </span>
        <a
          href={`https://basescan.org/address/${oracle}`}
          target="_blank"
          rel="noopener noreferrer"
          className="text-gray-400 hover:text-gray-600 dark:hover:text-gray-300 transition-colors"
        >
          <ExternalLinkIcon className="h-4 w-4" />
        </a>
      </div>
      <div className="font-mono text-sm bg-gray-100 dark:bg-gray-800 px-3 py-2 rounded">
        {truncateHash(oracle, 6)}
      </div>
    </div>
  );
};

import { useGetPoolsDataQuery } from "@/generated/sedge-graphql";
import { useEffect, useMemo } from "react";
import { Address, formatUnits, getAddress } from "viem";

interface Pool {
  id: Address;
  lptName: "Liquidity Pool Token";
  lptSymbol: "lpt";
  lptDecimals: number;
  lptPrice: number;
  lptTotal: string;
  assets: Address[];
  quoteOracle: Address;
  baseOracle: Address;
  oraclePriceDecimals: number;
  fee: string;
  reserveQuote: number;
  reserveBase: number;
  reserveUsd: number;
}
export interface Token {
  id: Address;
  oracle: Address;
  decimals: number;
  name: string;
  symbol: string;
  amount: number;
  icon: string;
}
interface Oracle {
  id: Address;
  token: string;
  decimals: number;
}

type PoolData = {
  pools: Record<Address, Pool>;
  oracles: Record<Address, Oracle>;
  tokens: Record<Address, Token>;
  poolAddrs: Address[];
  oracleAddrs: Address[];
  tokenAddrs: Address[];
};

const initState: PoolData = {
  pools: {},
  oracles: {},
  tokens: {},
  poolAddrs: [],
  oracleAddrs: [],
  tokenAddrs: [],
};

export const usePools = () => {
  // subgraph query
  const {
    data: rawSubgraphData,
    // loading: subgraphLoading,
    error: subgraphError,
  } = useGetPoolsDataQuery();

  useEffect(() => {
    if (subgraphError) {
      console.log(subgraphError);
    }
  }, [subgraphError]);

  const data = useMemo(() => {
    if (rawSubgraphData) {
      const _data = rawSubgraphData?.pairs.reduce<PoolData>((prev, curr) => {
        const poolAddr = getAddress(curr.id);
        const token0Addr = getAddress(curr.token0.id);
        const token1Addr = getAddress(curr.token1.id);
        const oracle0Addr = getAddress(curr.token0.oracle.id);
        const oracle1Addr = getAddress(curr.token1.oracle.id);

        const amountToken0 = Number(curr.reserve0);
        const amountToken1 = Number(curr.reserve1);

        const pool: Pool = {
          id: poolAddr,
          lptName: "Liquidity Pool Token",
          lptSymbol: "lpt",
          lptDecimals: curr.decimals,
          lptPrice:
            Number(curr.reserveUSD) > 0
              ? Number(curr.reserveUSD) / Number(curr.totalLPToken)
              : 1,
          lptTotal: curr.totalLPToken,
          assets: [token0Addr, token1Addr],
          quoteOracle: oracle0Addr,
          baseOracle: oracle1Addr,
          oraclePriceDecimals: 6,
          fee: formatUnits(curr.epsilon, 18),
          reserveBase: Number(curr.reserve0),
          reserveQuote: Number(curr.reserve1),
          reserveUsd: Number(curr.reserveUSD),
        };
        const token0: Token = {
          id: token0Addr,
          oracle: oracle0Addr,
          decimals: Number(curr.token0.decimals),
          name: curr.token0.name,
          amount: amountToken0,
          symbol: curr.token0.symbol,
          icon: `/assets/${curr.token0.symbol}.svg`,
        };
        const token1: Token = {
          id: token1Addr,
          oracle: oracle1Addr,
          decimals: Number(curr.token1.decimals),
          name: curr.token1.name,
          amount: amountToken1,
          symbol: curr.token1.symbol,
          icon: `/assets/${curr.token1.symbol}.svg`,
        };
        const oracle0: Oracle = {
          id: oracle0Addr,
          token: token0Addr,
          decimals: curr.token0.oracle.decimals,
        };
        const oracle1: Oracle = {
          id: oracle1Addr,
          token: token1Addr,
          decimals: curr.token1.oracle.decimals,
        };

        prev.pools[poolAddr] = pool;
        prev.tokens[token0Addr] = token0;
        prev.tokens[token1Addr] = token1;
        prev.oracles[oracle0Addr] = oracle0;
        prev.oracles[oracle1Addr] = oracle1;
        return prev;
      }, initState);

      _data.poolAddrs = Object.keys(_data.pools) as Address[];
      _data.tokenAddrs = Object.keys(_data.tokens) as Address[];
      _data.oracleAddrs = Object.keys(_data.oracles) as Address[];

      return _data;
    }
    return initState;
  }, [rawSubgraphData]);

  return {
    ...data,
  };
};

import { formatUnits } from "viem";
import { useBlockNumber } from "wagmi";

export const LatestBlock = () => {
  const blockNum = useBlockNumber({
    watch: { pollingInterval: 20_000, poll: true, enabled: true },
  });

  return (
    <>
      <div
        className="flex mr-4 items-center"
        title="Latest Ethereum block number"
      >
        <div className="mr-2 w-2 h-2 min-h-2 min-w-2 rounded bg-[rgb(118,209,145)]" />

        <span className="text-xs">
          {blockNum.data ? formatUnits(blockNum.data, 0) : "..."}
        </span>
      </div>

      <div className="text-xs">rev:{__GIT_HASH__}</div>
    </>
  );
};

import { animated } from "react-spring";
import { MoonIcon, SunIcon } from "@radix-ui/react-icons";

import { Button } from "@/components/ui/override/button";
import { useTheme } from "@/hooks/useTheme";
import { useBoop } from "@/hooks/useBoop";

export function ModeToggle() {
  const { setTheme, theme } = useTheme();

  const [styleClose, isBooped, triggerOpen, triggerClose] = useBoop({
    ry: 180,
    delay: 400,
  });

  const toggleTheme = () => {
    setTheme(theme === "light" ? "dark" : "light");
  };

  return (
    <Button
      variant={null}
      size="icon"
      onClick={toggleTheme}
      aria-label="Toggle theme"
      onMouseLeave={triggerClose}
    >
      <animated.div style={styleClose} onMouseEnter={triggerOpen}>
        {theme === "light" ? (
          isBooped ? (
            <MoonIcon className="h-[1.2rem] w-[1.2rem] hover:drop-shadow-[0_0_5px_rgba(255,255,0,0.5)]" />
          ) : (
            <SunIcon className="h-[1.2rem] w-[1.2rem]" />
          )
        ) : isBooped ? (
          <SunIcon className="h-[1.2rem] w-[1.2rem] hover:drop-shadow-[0_0_3px_rgba(255,255,255,1)]" />
        ) : (
          <MoonIcon className="h-[1.2rem] w-[1.2rem]" />
        )}
      </animated.div>
    </Button>
  );
}

import { Icons } from "@/components/icons";

interface NavItem {
  title: string;
  to?: string;
  href?: string;
  disabled?: boolean;
  external?: boolean;
  icon?: keyof typeof Icons;
  label?: string;
}

interface NavItemWithChildren extends NavItem {
  items?: NavItemWithChildren[];
}

export const MainMenu: NavItemWithChildren[] = [
  {
    title: "Swap",
    to: "/",
  },
  {
    title: "Pools",
    to: "/pools",
  },
];

import { RouterProvider } from "react-router-dom";
import { WagmiProvider } from "@privy-io/wagmi";
import { PrivyProvider } from "@privy-io/react-auth";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import { config, privyConfig } from "./config/config";
import { ApolloProviderWithClient } from "./contexts/subgraph-provider";
import { ThemeProvider } from "./contexts/theme-provider";
import { router } from "./Router";

const queryClient = new QueryClient();

function App() {
  return (
    <ThemeProvider>
      <PrivyProvider
        appId={import.meta.env.VITE_PRIVY_APP_ID}
        config={privyConfig}
      >
        <QueryClientProvider client={queryClient}>
          <WagmiProvider config={config}>
            <ApolloProviderWithClient>
              <RouterProvider router={router} />
            </ApolloProviderWithClient>
          </WagmiProvider>
        </QueryClientProvider>
      </PrivyProvider>
    </ThemeProvider>
  );
}

export default App;

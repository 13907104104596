export const IDfxZapAbi = [
  {
    inputs: [{ internalType: "address", name: "_factory", type: "address" }],
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    inputs: [
      { internalType: "address", name: "_curve", type: "address" },
      { internalType: "uint256", name: "_quoteAmount", type: "uint256" },
    ],
    name: "calcMaxBaseForDeposit",
    outputs: [
      { internalType: "uint256", name: "baseAmount", type: "uint256" },
      { internalType: "uint256", name: "usdAmount", type: "uint256" },
      { internalType: "uint256", name: "lptAmount", type: "uint256" },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "_curve", type: "address" },
      { internalType: "uint256", name: "_baseAmount", type: "uint256" },
    ],
    name: "calcMaxDepositAmountGivenBase",
    outputs: [
      { internalType: "uint256", name: "", type: "uint256" },
      { internalType: "uint256", name: "", type: "uint256" },
      { internalType: "uint256[]", name: "", type: "uint256[]" },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "_curve", type: "address" },
      { internalType: "uint256", name: "_quoteAmount", type: "uint256" },
    ],
    name: "calcMaxDepositAmountGivenQuote",
    outputs: [
      { internalType: "uint256", name: "", type: "uint256" },
      { internalType: "uint256", name: "", type: "uint256" },
      { internalType: "uint256[]", name: "", type: "uint256[]" },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "_curve", type: "address" },
      { internalType: "uint256", name: "_baseAmount", type: "uint256" },
    ],
    name: "calcMaxQuoteForDeposit",
    outputs: [
      { internalType: "uint256", name: "quoteAmount", type: "uint256" },
      { internalType: "uint256", name: "usdAmount", type: "uint256" },
      { internalType: "uint256", name: "lptAmount", type: "uint256" },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "_curve", type: "address" },
      { internalType: "uint256", name: "_zapAmount", type: "uint256" },
      { internalType: "bool", name: "isFromBase", type: "bool" },
    ],
    name: "calcSwapAmountForZap",
    outputs: [
      { internalType: "address", name: "", type: "address" },
      { internalType: "uint256", name: "", type: "uint256" },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "_curve", type: "address" },
      { internalType: "uint256", name: "_zapAmount", type: "uint256" },
    ],
    name: "calcSwapAmountForZapFromBase",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "_curve", type: "address" },
      { internalType: "uint256", name: "_zapAmount", type: "uint256" },
    ],
    name: "calcSwapAmountForZapFromQuote",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "curveFactory",
    outputs: [
      {
        internalType: "contract ICurveFactory",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "_curve", type: "address" },
      { internalType: "uint256", name: "_lpAmount", type: "uint256" },
      { internalType: "uint256", name: "_deadline", type: "uint256" },
      {
        internalType: "uint256",
        name: "_minTokenAmount",
        type: "uint256",
      },
      { internalType: "address", name: "_token", type: "address" },
      { internalType: "bool", name: "_toETH", type: "bool" },
    ],
    name: "unzap",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "_curve", type: "address" },
      { internalType: "uint256", name: "_zapAmount", type: "uint256" },
      { internalType: "uint256", name: "_deadline", type: "uint256" },
      { internalType: "uint256", name: "_minLPAmount", type: "uint256" },
      { internalType: "address", name: "_token", type: "address" },
    ],
    name: "zap",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "_curve", type: "address" },
      { internalType: "uint256", name: "_deadline", type: "uint256" },
      { internalType: "uint256", name: "_minLPAmount", type: "uint256" },
    ],
    name: "zapETH",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "payable",
    type: "function",
  },
] as const;

import { useMemo } from "react";
import { parseUnits } from "viem";

import { useBalances } from "@/hooks/useBalances";
import { Token } from "@/hooks/usePools";
import { toW3Number, W3Number } from "@/lib/w3Num";
import { capitalize, toMaxSigFig } from "@/lib/utils";

interface Props {
  direction: "deposit" | "withdraw";
  token: Token;
  inputValue: W3Number | null;
  onChange: (val: W3Number | null) => void;
  tokenOptions?: Token[];
  setToken?: (t: Token) => void;
}
export const LiquidityTokenInput: React.FC<Props> = ({
  direction = "deposit",
  token,
  inputValue,
  onChange,
}) => {
  const { balances } = useBalances();

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value !== "") {
      const newValue = toW3Number(
        parseUnits(event.target.value, token.decimals),
        token.decimals,
        6
      );
      onChange(newValue);
    } else {
      onChange(null);
    }
  };

  const handleSetBalance = () => {
    if (balances && balances[token.id]) {
      onChange(balances[token.id]);
    }
  };

  const bal = useMemo(
    () => toMaxSigFig(balances?.[token.id].dsp),
    [balances, token.id]
  );

  return (
    <div className="bg-gray-100 dark:bg-gray-800/50 p-4 space-y-2 rounded">
      <div className="flex justify-between">
        <span className="text-gray-600">{capitalize(direction)} Token</span>
        <div className="flex gap-x-1">
          <span className="text-gray-400">Max:</span>
          <div
            className="cursor-pointer"
            onClick={handleSetBalance}
            onKeyDown={handleSetBalance}
            role="button"
            tabIndex={0}
          >
            {bal}
          </div>
        </div>
      </div>
      <div className="flex items-center justify-between bg-white dark:bg-gray-900 rounded p-4">
        <input
          type="number"
          placeholder="0.0"
          className="bg-transparent text-lg outline-none w-full"
          value={inputValue ? parseFloat(inputValue.hStr) : "--"}
          onChange={handleOnChange}
        />
        <div className="flex items-center gap-2 h-6 px-4 py-2">
          <span className="text-xl whitespace-nowrap">{token.symbol}</span>
          <img alt={`${token.symbol} icon`} src={token.icon} width={24} />
        </div>
      </div>
    </div>
  );
};

import { ExternalLinkIcon } from "@radix-ui/react-icons";
import { Address } from "viem";
import { CurrencyPairIcon } from "../currency-pair-icon";

interface Props {
  id: Address;
  pairLabel: string;
  baseIcon: string;
  quoteIcon: string;
}

export const PoolTitle: React.FC<Props> = ({
  id,
  baseIcon,
  quoteIcon,
  pairLabel,
}) => {
  return (
    <div className="flex items-center gap-2">
      <CurrencyPairIcon baseIcon={baseIcon} quoteIcon={quoteIcon} />
      <div className="flex items-center font-medium">
        {pairLabel}
        <a
          href={`https://basescan.org/address/${id}`}
          target="_blank"
          rel="noopener noreferrer"
          className="ml-2 flex-shrink-0 text-gray-400 hover:text-gray-600 dark:hover:text-gray-300 transition-colors"
          aria-label={`View ${pairLabel} on Basescan`}
        >
          <ExternalLinkIcon className="h-4 w-4" />
        </a>
      </div>
    </div>
  );
};

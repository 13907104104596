import { Button } from "@/components/ui/button";
import { capitalize } from "@/lib/utils";
import { StatusDot } from "../status-dot";

interface Props {
  status: "balanced" | "imbalanced";
}
export const PoolStatus: React.FC<Props> = ({ status }) => {
  return (
    <div className="bg-gray-50 dark:bg-gray-800/50 rounded-lg p-4 h-24">
      <div className="flex items-center justify-between mb-3">
        <span className="text-gray-400 text-sm font-medium">Pool Status</span>
        <Button
          variant="ghost"
          size="icon"
          className="h-4 w-4 text-gray-400 hover:text-gray-600 dark:hover:text-gray-300"
        />
      </div>
      <div className="flex items-center gap-2">
        <StatusDot />
        <span className="font-medium">{capitalize(status)}</span>
      </div>
    </div>
  );
};

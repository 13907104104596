import { useState } from "react";
import { CogIcon, X } from "lucide-react";

import { Dialog, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { DialogContent } from "@/components/ui/override/dialog";
import { CurrencyPairIcon } from "@/components/currency-pair-icon";
import { Pool } from "@/pages/Pools";
import { EditTxSettingsModal } from "../tx-settings-modal";
import { AddOneSidedField } from "./add-one-sided-field";
import { AddTwoSidedFields } from "./add-two-sided-fields";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  pool: Pool;
}
export const AddLiquidityModal: React.FC<Props> = ({
  isOpen,
  onClose,
  pool,
}) => {
  const [depositType, setDepositType] = useState<"Two Sided" | "Single Sided">(
    "Two Sided"
  );
  const [isTxSettingsOpen, setIsTxSettingsOpen] = useState(false);

  return (
    <>
      <Dialog open={isOpen} onOpenChange={onClose}>
        <DialogContent className="bg-white dark:bg-gray-900 border-gray-800 max-w-xl p-4">
          <DialogHeader className="border-b dark:border-gray-800 pb-4">
            <div className="flex items-center justify-between">
              <DialogTitle className="text-xl font-normal px-1">
                Add Liquidity
              </DialogTitle>
              <div>
                <Button
                  variant="ghost"
                  onClick={() => setIsTxSettingsOpen(true)}
                >
                  <CogIcon width={18} />
                </Button>
                <Button
                  variant="ghost"
                  size="icon"
                  onClick={onClose}
                  className="hover:bg-gray-100 dark:hover:bg-gray-800"
                >
                  <X className="h-5 w-5" />
                </Button>
              </div>
            </div>
          </DialogHeader>

          <div className="space-y-4 py-4">
            {/* Pool Selector */}
            <div className="rounded bg-gray-100 dark:bg-gray-800/50 p-4">
              <div className="flex items-center justify-between">
                <span className="text">Adding liquidity to</span>
                <div className="flex items-center gap-2">
                  <CurrencyPairIcon
                    baseIcon={pool.baseToken.icon}
                    quoteIcon={pool.quoteToken.icon}
                  />
                  <span className="text-xl">{pool.label}</span>
                </div>
              </div>
            </div>

            {/* Deposit Type Selector */}
            <div className="grid grid-cols-1 gap-2 p-1 rounded bg-gray-100 dark:bg-gray-800">
              <Button
                variant={depositType === "Two Sided" ? "default" : "ghost"}
                onClick={() => setDepositType("Two Sided")}
                className={`rounded ${
                  depositType === "Two Sided"
                    ? "dark:bg-blue-600"
                    : "hover:bg-gray-700"
                }`}
              >
                Two Sided
              </Button>

              {/* 
              <Button
                variant={depositType === "Single Sided" ? "default" : "ghost"}
                onClick={() => setDepositType("Single Sided")}
                className={`rounder ${
                  depositType === "Single Sided"
                    ? "bg-blue-600"
                    : "hover:bg-gray-700"
                }`}
              >
                Single Sided
              </Button>
              */}
            </div>

            {/* Deposit Input */}
            {depositType === "Single Sided" ? (
              <AddOneSidedField pool={pool} closeModal={onClose} />
            ) : (
              <AddTwoSidedFields pool={pool} closeModal={onClose} />
            )}
          </div>
        </DialogContent>
      </Dialog>

      <EditTxSettingsModal
        isOpen={isTxSettingsOpen}
        onClose={() => setIsTxSettingsOpen(false)}
      />
    </>
  );
};

import { Outlet } from "react-router-dom";

import { Footer } from "@/components/footer";
import { Header } from "@/components/header";

export function AppLayout() {
  return (
    <div className="flex flex-col min-h-screen">
      <div className="flex flex-grow flex-col max-h-40 px-[5%] sm:px-[10%] lg:px-[20%] relative">
        <div className="absolute z-[-1] w-full top-0 left-0 py-2 dark:py-1 bg-gradient-to-b from-muted dark:from-sky-200/50 to-transparent" />
        <Header />
      </div>

      <div className="flex flex-grow flex-col px-[5%] sm:px-[10%] lg:px-[20%]">
        <Outlet />
      </div>

      <div className="px-4 md:px-8 bottom-0 relative">
        <Footer />
        <div className="absolute z-[-1] w-full bottom-0 left-0 py-40 bg-gradient-to-t from-muted to-transparent dark:from-black" />
      </div>
    </div>
  );
}

import { useEffect, useMemo, useState } from "react";
import { useReadContract } from "wagmi";

import { IDfxRouterAbi } from "@/abi/IDfxRouter";
import { BASE_ROUTER_ADDR } from "@/config/addresses";
import { toW3Number, W3Number } from "@/lib/w3Num";
import { Token } from "./usePools";

import { useSwapRoute } from "./useSwapRoute";
import { useAtomValue } from "jotai";
import { txSettingsAtom } from "@/contexts/atoms";
import { SwapField } from "@/lib/constants";

export const calcAmountWithSlippage = (
  amount: bigint,
  bps: number,
  reverse = false
) => {
  if (bps > 10000) {
    throw new Error("Max slippage bps must be less than 10000");
  }

  if (reverse) {
    return (amount * BigInt(10000 + bps)) / BigInt(10000);
  } else {
    return (amount * BigInt(10000 - bps)) / BigInt(10000);
  }
};

interface CalcSwapArgs {
  inToken: Token | null;
  outToken: Token | null;
  inAmount: W3Number;
  outAmount: W3Number;
  exactToken: SwapField;
}
export const useCalcSwap = ({
  inToken,
  outToken,
  inAmount,
  outAmount,
  exactToken,
}: CalcSwapArgs) => {
  const [swapError, setSwapError] = useState<string | null>(null);
  const txSettings = useAtomValue(txSettingsAtom);

  const [tokenA, tokenB, amountA] = useMemo(() => {
    if (exactToken === SwapField.InToken) {
      return [inToken, outToken, inAmount];
    } else {
      return [outToken, inToken, outAmount];
    }
  }, [inToken, inAmount, outToken, outAmount, exactToken]);

  const { tokenRoute } = useSwapRoute(tokenA, tokenB);

  // estimate swap
  const {
    data: estimatedAmount,
    isError,
    status,
    failureReason,
  } = useReadContract({
    abi: IDfxRouterAbi,
    address: BASE_ROUTER_ADDR,
    functionName: "viewOriginSwap",
    args: [tokenRoute, amountA.big],
    query: {
      retry: 0,
      enabled: amountA.big > 0n,
    },
  });

  // set error state if encounted
  useEffect(() => {
    if (isError && failureReason) {
      setSwapError(failureReason.shortMessage);
    } else {
      setSwapError(null);
    }
  }, [status, isError, failureReason]);

  // calculate slippage and protocol price
  const { minAmount, spotPrice } = useMemo(() => {
    if (tokenA && tokenB && estimatedAmount) {
      // With slippage amount
      // out - out * slip = out * (1 - slip)
      const maxSlippagePercent =
        parseFloat(txSettings.maxSlippagePercent) * 100;

      const minAmount = calcAmountWithSlippage(
        estimatedAmount,
        maxSlippagePercent
      );

      // Spot price
      // outputAmount / inputAmount = (rawOut / outdec) / (rawIn / inDec)
      const scale = BigInt(10 ** (18 + tokenB.decimals - tokenA.decimals));
      const rawSpotPrice = (estimatedAmount * scale) / amountA.big;
      const spotPrice = Number(rawSpotPrice) / 1e18; // Divide out 18 extra dec
      return {
        minAmount,
        spotPrice,
      };
    }
    return {
      minAmount: null,
      spotPrice: null,
    };
  }, [
    tokenA,
    tokenB,
    amountA.big,
    txSettings.maxSlippagePercent,
    estimatedAmount,
  ]);

  // return the exact amounts and estimates depending on last typed state
  const [estimatedInAmount, minInAmount, estimatedOutAmount, minOutAmount] =
    useMemo(() => {
      if (estimatedAmount && minAmount && inToken && outToken) {
        if (exactToken === SwapField.InToken) {
          return [
            inAmount,
            inAmount,
            toW3Number(estimatedAmount, outToken.decimals, 6),
            toW3Number(minAmount, outToken.decimals, 6),
          ];
        } else {
          return [
            toW3Number(estimatedAmount, inToken.decimals, 6),
            toW3Number(minAmount, inToken.decimals, 6),
            outAmount,
            outAmount,
          ];
        }
      }
      return [null, null, null, null];
    }, [
      estimatedAmount,
      minAmount,
      inToken,
      inAmount,
      outToken,
      outAmount,
      exactToken,
    ]);

  return {
    estimatedInAmount,
    estimatedOutAmount,
    minInAmount,
    minOutAmount,
    spotPrice,
    swapError,
  };
};

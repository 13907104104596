import {
  getEmbeddedConnectedWallet,
  usePrivy,
  useWallets,
} from "@privy-io/react-auth";
import { Address } from "viem";
import { useAccount, useWalletClient } from "wagmi";

// Define a type that includes all possible fields from both libraries
type AdaptiveTransactionRequest = {
  to: Address;
  data?: Address;
  value?: bigint;
  from?: Address;
  nonce?: number;
  gas?: bigint;
  gasLimit?: bigint;
  gasPrice?: bigint;
  maxFeePerGas?: any;
  maxPriorityFeePerGas?: any;
  accessList?: { address: Address; storageKeys: Address[] }[];
  type?: any;
};

// Use Privy-enabled hook when app type is "default"
const useAdaptiveWallet = () => {
  const { sendTransaction: privySendTransaction } = usePrivy();
  const { address } = useAccount();
  const { wallets } = useWallets();
  const embeddedWallet = getEmbeddedConnectedWallet(wallets);

  const { data: walletClient } = useWalletClient();

  const adaptiveSendTransaction = async (
    transactionRequest: AdaptiveTransactionRequest
  ): Promise<Address> => {
    // use Privy embedded wallet flow (email, social)
    if (!window.ethereum && embeddedWallet) {
      const receipt = await privySendTransaction(transactionRequest);
      return receipt.transactionHash as Address;
    }
    // use wagmi for default actions
    else if (walletClient) {
      return await walletClient.sendTransaction({
        ...transactionRequest,
        account: address,
      });
    } else {
      throw new Error("No suitable wallet method found");
    }
  };

  return { sendTransaction: adaptiveSendTransaction };
};

export default useAdaptiveWallet;

import { createBrowserRouter } from "react-router-dom";

import { AppLayout } from "./pages/AppLayout";
import { ErrorPage } from "./pages/Error";
import Pools from "./pages/Pools";
import Swap from "./pages/Swap";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <AppLayout />,
    errorElement: import.meta.env.DEV ? null : <ErrorPage />, // hide 404 in development mode
    children: [
      {
        path: "",
        element: <Swap />,
      },
      {
        path: "pools",
        element: <Pools />,
      },
    ],
  },
]);

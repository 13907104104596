import { EyeNoneIcon } from "@radix-ui/react-icons";

import { Button } from "@/components/ui/button";
import { useDisconnect } from "wagmi";

export const Disconnect: React.FC = () => {
  const { disconnect } = useDisconnect();

  const handleDisconnect = () => {
    disconnect();
  };

  return (
    <Button
      className="w-full bg-warning hover:bg-warning hover:brightness-125 text-warning-foreground"
      onClick={handleDisconnect}
    >
      <div className="flex w-full items-center justify-center">
        <div className="relative [&>svg]:text-primary-foreground/50">
          <EyeNoneIcon
            height={12}
            width={12}
            className="absolute -left-5 top-1"
          />
          <div>Disconnect</div>
        </div>
      </div>
    </Button>
  );
};

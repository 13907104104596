import { useCallback, useEffect, useState } from "react";
import { useSetAtom } from "jotai";
import { Address, encodeFunctionData, Hash } from "viem";
import { waitForTransactionReceipt } from "@wagmi/core";
import { useAccount, useReadContract } from "wagmi";

import { IERC20Abi } from "@/abi/IERC20";
import { config } from "@/config/config";
import { toast } from "@/hooks/useToast";
import { currencyInputStatusAtom } from "@/contexts/atoms";
import useAdaptiveWallet from "@/hooks/useAdaptiveWallet";
import { debug, sleep } from "@/lib/utils";
import { W3Number } from "@/lib/w3Num";

export const APPROVE_DELAY_MS = 1000;

export const useApprove = (
  tokenAddr: Address,
  spenderAddr: Address,
  amount: W3Number | null,
  enabled = true
) => {
  const [txHash, setTxHash] = useState<Hash | null>(null);
  const [approveRequired, setApproveRequired] = useState(false);

  const { address } = useAccount();
  const { sendTransaction: adaptiveSendTransaction } = useAdaptiveWallet();
  const setStatus = useSetAtom(currencyInputStatusAtom);

  const { data } = useReadContract({
    abi: IERC20Abi,
    address: tokenAddr,
    functionName: "allowance",
    args: [address ?? "0x", spenderAddr],
    query: {
      enabled: !!address,
    },
  });

  useEffect(() => {
    if (amount && data !== undefined) {
      setApproveRequired(amount.big > data);
    }
  }, [amount, data]);

  const approve = useCallback(async () => {
    debug("useApprove");

    if (address && amount && approveRequired && enabled) {
      try {
        const approvalHash = await adaptiveSendTransaction({
          to: tokenAddr,
          data: encodeFunctionData({
            abi: IERC20Abi,
            functionName: "approve",
            args: [spenderAddr, amount.big],
          }),
        });

        const tx = await waitForTransactionReceipt(config, {
          hash: approvalHash,
        });
        await sleep(APPROVE_DELAY_MS);

        setTxHash(tx.transactionHash);
        return tx.transactionHash;
      } catch {
        toast({ title: `Approval failed` });
        setStatus("error");
      }
    }

    return null;
  }, [
    adaptiveSendTransaction,
    address,
    amount,
    approveRequired,
    spenderAddr,
    tokenAddr,
    setStatus,
    enabled,
  ]);

  return {
    approveRequired,
    executeApprove: approve,
    txHash,
  };
};
